import { DecoratedGroup } from '@/features-slice/group/GroupSlice';
import { useTranslation } from '@/i18n/useTranslation';
import { useState } from 'react';
import { BsHash } from 'react-icons/bs';
import { FaChevronDown, FaChevronRight, FaPlus } from 'react-icons/fa';

interface Props {
  parentGroup: DecoratedGroup;
  isRoot?: boolean;
  onSelect: (uuid: string) => void;
  selectedUuid: string;
}
export const GroupHierarchy = (p: Props) => {
  const { parentGroup, onSelect, selectedUuid } = p;
  const selections = parentGroup.children?.map((g) => ({ key: g.uuid, label: g.name })).sort((a, b) => a.label.localeCompare(b.label));
  const [expanded, setExpanded] = useState(true);

  return (
    <div className={`h-full m-0 overflow-hidden shadow-lg bg-primary w-80`}>
      {/* {p.isRoot && <MenuBlock />} */}
      <div className="flex flex-col items-center justify-start p-1 m-0">
        <GroupDropdown
          expanded // FIXME
          parentUuid={parentGroup.uuid}
          title={`${parentGroup.name} (${parentGroup.children?.length ?? 0})`}
          selectedUuid={selectedUuid}
          selections={selections}
          onSelect={onSelect}
          onExpand={() => {
            onSelect(parentGroup.uuid);
            setExpanded(!expanded);
          }}
        />
      </div>
    </div>
  );
};

export type DSelection = {
  key: string;
  label: string;
};
export type DProps = {
  selections: DSelection[];
  selectedUuid: string;
  parentUuid: string;
  title: string;
  expanded?: boolean;
  onExpand?: () => void;
  onSelect?: (uuid: string) => void;
};

const GroupDropdown = ({ title: header, selections, onExpand, expanded, onSelect, selectedUuid, parentUuid: rootUuid }: DProps) => {
  const selected = rootUuid === selectedUuid;

  return (
    <div className="w-full px-2 pb-2 m-0">
      <div
        onClick={onExpand}
        className="flex flex-row items-center mx-0 text-neutral-500 cursor-pointer justify-evenly"
      >
        <ChevronIcon expanded={expanded} selected={selected} />
        <h5
          className={`text-lg 
          text-opacity-90 ${
            selected ? 'font-bold text-white' : 'cursor-default font-semibold text-neutral-500'
          }`}
        >
          {header}
        </h5>
        <FaPlus size="12" className="my-auto ml-auto text-accent text-opacity-80" />
      </div>
      {expanded &&
        selections &&
        selections.map((selection, _) => (
          <TopicSelection
            key={selection.key}
            onSelect={onSelect}
            selection={selection}
            selected={selectedUuid === selection.key}
          />
        ))}
    </div>
  );
};

const ChevronIcon = ({ expanded, selected }: any) => {
  const chevClass = `text-accent text-opacity-80 my-auto mr-1 ${selected ? 'text-white' : ''}`;
  return expanded ? (
    <FaChevronDown size="14" className={chevClass} />
  ) : (
    <FaChevronRight size="14" className={chevClass} />
  );
};

const TopicSelection = ({ selection, onSelect, selected }: any) => (
  <div className="flex flex-row items-center mt-1 ml-2 mr-auto cursor-pointer justify-evenly ">
    <BsHash size="24" className={selected ? "text-white" : "text-neutral-500"} />
    <h6
      className={`
        mr-auto tracking-wide cursor-pointer  ${selected ? 'font-extrabold text-white' : 'font-semibold text-neutral-500 hover:text-neutral-400'}
        `}
      onClick={() => onSelect(selection.key)}
    >
      {/* FIXME url params */}
      {/* <Link to={routesMap.network_detail.path.replace(':uuid', selection.key)}>
        {selection.label}
      </Link> */}
      {selection.label}
    </h6>
  </div>
);

const MenuBlock = () => {
  const t = useTranslation();
  return (
    <div className="flex items-center justify-center h-16 p-0 m-0">
      <h4 className="my-auto ml-4 mr-auto text-2xl font-bold tracking-wider align-middle text-light">
        {t('groups')}
      </h4>
    </div>
  );
};
