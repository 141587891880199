import { Icons } from '@/components/icons';
import { useTranslation } from '@/i18n/useTranslation';
import { Divider, Paper, TableRow, TextField, Typography, styled } from '@mui/material';

export const B2BGlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) => {
  const count = preGlobalFilteredRows.length;
  const t = useTranslation();

  return (
    <>
      <Paper className="p-2 w-full flex flex-row items-center my-2">
        <Icons.Search className="mr-2" />
        <TextField
          fullWidth
          className="ml-2 text-xs"
          size="small"
          value={globalFilter || ''}
          onChange={(e) => {
            setGlobalFilter(e.target.value);
          }}
          placeholder={
            !globalFilter?.length ? t('search.placeholder') : `${count} ${t('search.results')}...`
          }
        />
      </Paper>
      {/* count is not dynamic FIXME */}
      <Divider flexItem className="my-4">
        <Typography variant="caption">{`${count} ${t('frames')}`}</Typography>
      </Divider>
    </>
  );
};

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // @ts-ignore
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
