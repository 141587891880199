import React, { ComponentPropsWithRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../components/Button/index';
import { useItemsLoader } from '@/hooks/useItemsLoader';
import { fetchGroups } from '@/features-slice/group/GroupSlice';
import { Icons } from '@/components/icons';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { useTranslation } from '@/i18n/useTranslation';

type Props = ComponentPropsWithRef<'div'> & {
  onClose: () => void;
  batchUuid: string;
  onConfirm: (startDate: Date) => void;
};
export const DispatchModal = (p: Props) => {
  const { className, onClose, onConfirm, batchUuid, style, ...rest } = p;
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(null);
  const { entities: groups } = useItemsLoader({
    rootEntitiesSelector: (s) => s.group,
    loaderThunk: fetchGroups,
  });

  const [selection, setSelection] = useState<{
    batchUuid: string;
    groupUuid?: string;
  } | null>({
    batchUuid,
    groupUuid: groups[0]?.uuid,
  });

  const t = useTranslation();

  return (
    <Dialog
      open={!!batchUuid}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle sx={{ mr: 2, p: 2 }} id="customized-dialog-title">
        {t('batch.modal.title')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers className="p-8">
        {/* FIXME i18n */}
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <Box>
            <Typography variant="body2">
              {groups.find((g) => g.uuid === selection?.groupUuid)?.name}
            </Typography>
            {/* <Typography variant="body2">Select target group</Typography> */}
            {/* <ActionMenu
              title={groups.find(g => g.uuid === selection?.groupUuid)?.name ?? 'Select a group'}
              actions={groups.slice(0, 4).map((g) => ({
                title: g.name,
                action: () => {
                  setSelection({
                    batchUuid,
                    groupUuid: g.uuid,
                  });
                },
              }))}
            /> */}
          </Box>
          <br />
          <Box sx={style} className="space-x-2">
            <DateTimePicker
              disablePast
              label={t('batch.startdate')}
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
            {/* <DateTimePicker
              label={t('batch.enddate')}
              minDate={startDate.add(1, 'day')}
              value={endDate}
              onChange={(date) => setEndDate(date)}
            /> */}
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button className="w-3/4 mx-auto" autoFocus onClick={() => onConfirm(startDate.toDate())}>
          <Icons.Send fontSize="small" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
