import { ScheduledDispatchResponse } from '@/features-slice/dispatch/scheduledDispatchDTO';
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ComponentPropsWithRef } from 'react';
import { Icons } from '@/components/icons';
import { dateStrToLocaleDate, dateStrToLocale } from '@ionnyk-npm/common-ts';
import { StyledTableRow } from '@/utils/tables';
import { useAppDispatch } from '@/stores/useReduxTK';
import { changeStatus } from '@/features-slice/dispatch/scheduledDispatchSlice';
import { useEntitiesLoader } from '@/hooks/useEntitiesLoader';
import { fetchBatches } from '@/features-slice/batch/batchSlice';
import { browserAppLang, useTranslation } from '@/i18n/useTranslation';
import { AccordionWrapper } from './AccordionWrapper';

interface Props extends ComponentPropsWithRef<'div'> {
  dispatches: ScheduledDispatchResponse[];
  selectedDispatchUuid?: string;
  onSelectedDispatch?: (uuid: string) => void;
}
const BatchColorMapper: Record<
  ScheduledDispatchResponse['status'],
  'info' | 'default' | 'primary' | 'secondary' | 'error' | 'success' | 'warning'
> = {
  SCHEDULED: 'info',
  ACTIVE: 'success',
  DONE: 'secondary',
  IN_ERROR: 'error',
  CANCELED: 'warning',
};

export const DispatchList = (p: Props) => {
  const { className, dispatches, selectedDispatchUuid, onSelectedDispatch, ...rest } = p;
  const dispatch = useAppDispatch();
  const { items: batchMap, loading } = useEntitiesLoader({
    rootEntitiesSelector: (s) => s.batches,
    loaderThunk: fetchBatches,
  });
  const t = useTranslation();

  const sortedDispatches = dispatches.sort((a, b) => {
    const v =
      new Date(b.plannedStart ?? b.startDispatchMoment).getTime() -
      new Date(a.plannedStart ?? b.startDispatchMoment).getTime();
    return v;
  });

  const doneDispatches = sortedDispatches.filter((d) => d.status === 'DONE');
  const restDispatches = sortedDispatches.filter((d) => d.status !== 'DONE');

  return (
    <div className={className} {...rest}>
      <TableContainer className="overflow-x-auto">
        <Table>
          <TableHead className="bg-secondary">
            <TableRow>
              <TableCell className="font-bold">{t('dispatch.status')}</TableCell>
              <TableCell className="font-bold">{t('dispatch.batch')}</TableCell>
              <TableCell className="font-bold">{t('dispatch.created')}</TableCell>
              <TableCell className="font-bold">{t('dispatch.start')}</TableCell>
              <TableCell className="font-bold">{t('dispatch.end')}</TableCell>
              <TableCell className="font-bold">{''}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {restDispatches.map((b) => (
              <StyledTableRow
                hover
                key={b.uuid}
                onClick={() => onSelectedDispatch(b.uuid)}
                className={`p-2 ${b.uuid === selectedDispatchUuid ? 'bg-slate-300' : ''}`}
              >
                <TableCell>
                  <Chip size="small" label={t(b.status)} color={BatchColorMapper[b.status]} />
                </TableCell>
                <TableCell>
                  {batchMap?.[b.batchUuid]?.name?.[browserAppLang] ??
                    batchMap?.[b.batchUuid]?.name?.en ??
                    '...'}
                </TableCell>
                <TableCell>{dateStrToLocaleDate(b.creationDate)}</TableCell>
                <TableCell>
                  {dateStrToLocale(b.startedOn ?? b.plannedStart ?? b.startDispatchMoment)}
                </TableCell>
                <TableCell>{dateStrToLocale(b.endedOn ?? b.endDispatchMoment) || '/'}</TableCell>
                <TableCell>
                  {b.status === 'SCHEDULED' && (
                    <Button
                      title={t('dispatch.cancel')}
                      onClick={() => {
                        dispatch(changeStatus({ dispatchUuid: b.uuid, status: 'CANCELED' }));
                      }}
                    >
                      <Icons.Stop fontSize="medium" />
                    </Button>
                  )}
                  {/* {b.status === 'DONE' && (
                    <Button title="Replay">
                      <Icons.PlayArrow fontSize="medium" />
                    </Button>
                  )} */}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AccordionWrapper 
        title={`${t('DONE')} (${doneDispatches.length})`}
        className="p-0 m-0"
        titleClassName="bg-neutral-300 mt-4"
        bodyClassName="p-0 m-0"
        >
        <TableContainer className="overflow-x-auto">
          <Table>
            <TableBody>
            {doneDispatches.map((b) => (
              <StyledTableRow
                hover
                key={b.uuid}
                onClick={() => onSelectedDispatch(b.uuid)}
                className={`p-2 ${b.uuid === selectedDispatchUuid ? 'bg-slate-300' : ''}`}
              >
                <TableCell>
                  <Chip size="small" label={t(b.status)} color={BatchColorMapper[b.status]} />
                </TableCell>
                <TableCell>
                  {batchMap?.[b.batchUuid]?.name?.[browserAppLang] ??
                    batchMap?.[b.batchUuid]?.name?.en ??
                    '...'}
                </TableCell>
                <TableCell>{dateStrToLocaleDate(b.creationDate)}</TableCell>
                <TableCell>
                  {dateStrToLocale(b.startedOn ?? b.plannedStart ?? b.startDispatchMoment)}
                </TableCell>
                <TableCell>{dateStrToLocale(b.endedOn ?? b.endDispatchMoment) || '/'}</TableCell>
                <TableCell>
                  {b.status === 'SCHEDULED' && (
                    <Button
                      title={t('dispatch.cancel')}
                      onClick={() => {
                        dispatch(changeStatus({ dispatchUuid: b.uuid, status: 'CANCELED' }));
                      }}
                    >
                      <Icons.Stop fontSize="medium" />
                    </Button>
                  )}
                  {/* {b.status === 'DONE' && (
                    <Button title="Replay">
                      <Icons.PlayArrow fontSize="medium" />
                    </Button>
                  )} */}
                </TableCell>
              </StyledTableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionWrapper>
    </div>
  );
};
