import { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from '@/i18n/useTranslation';
import { useAppDispatch, useAppSelector } from '@/stores/useReduxTK';
import { loginAsync, mfaAsync, resetServerError } from '@/features-slice/auth/slice/signinSlice';
import { Navigate, useLocation } from 'react-router-dom';
import { Footer } from '@/components/Footer';
import logoBlack from '@/assets/ionnyk_logo_black.png';
import { useNewUrlParams } from '@/hooks/useNewURLParams';
import { MainFlexContainer } from '@/components/MainFlexContainer';
import { Button } from '@/components/Button';
import { Input } from '@/components/Input';
import { Col } from '@/components/Col';
import { Row } from '@/components/Row';
import { GridContainer } from '@/components/GridContainer';
import { H1 } from '@/components/H1';
import { Form } from '@/components/Form';
import { HRef } from '@/components/HRef/HRef';
import { H3 } from '@/components/H3';
import { routesMap } from '../routes';
import { MFAModal } from './MFAModal';
import { TKeys } from '@/i18n/en';
import { ENV_VARS } from '@/api-services/baseURLs';

export const SignIn = () => {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const authState = useAppSelector((state) => state.login);
  // const idRef = useRef(Math.random().toString());

  const params = useNewUrlParams();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  // const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const displayServerError =
    !!authState.serverError && _.isEqual({ email, password }, authState.serverError.submittedData);

  if (authState.loggedIn) {
    return (
      <Navigate to={from === routesMap.root.path ? routesMap.network.path : from} replace={true} />
    );
  }

  return (
    <MainFlexContainer className="h-screen">
      <MFAModal
        onClose={resetServerError}
        session={authState.serverError?.originalError?.session}
        onSubmit={async (verificationCode: string) => {
          console.info('submitCode', { verificationCode });
          await dispatch(mfaAsync(verificationCode));
        }}
      />
      <GridContainer>
        <Row style={{ marginBottom: '5rem' }}>
          <Col className="my-12">
            <img
              className="block mx-auto mb-2 w-96 max-w-sm h-auto"
              src={logoBlack}
              alt="Ionnyk logo"
            />
            <H1 className="mx-auto text-center">{t('network.manager')}</H1>
          </Col>
        </Row>
        <Row>
          <Col className="col-span-8">
            <H3 className="mx-auto text-center">{t('signin.title')}</H3>
            <Form>
              <Input
                type="email"
                disabled={authState.loading}
                autoFocus
                autoComplete="username email"
                placeholder={t('signin.email')}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />

              <Input
                type="password"
                disabled={authState.loading}
                autoComplete="current-password"
                placeholder={t('signin.psw')}
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
              <Button
                className="btn-primary w-full"
                type="submit"
                id="login-b2b-btn"
                disabled={authState.loading}
                onClick={async (e) => {
                  e.preventDefault(); // stop submit form
                  e.stopPropagation();
                  console.info('Login', { email, password });
                  await dispatch(
                    loginAsync({
                      email,
                      password,
                    })
                  );
                }}
              >
                {t(authState.loading ? 'loading' : 'signin.btn')}
              </Button>
              {displayServerError && (
                <span style={{ color: 'red' }}>
                  {(authState.serverError.statusCode
                    ? String(authState.serverError.statusCode)  + ' - '
                    : '') + t(authState.serverError.errorKey as TKeys)}
                </span>
              )}
              {/* <Hr />
              <Button
                className="w-full"
                disabled={authState.loading}
                onClick={() => window.open('https://my.ionnyk.com/signup', '_self')}
              >
                {t('signup.btn')}
              </Button> */}
            </Form>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="col-span-6 flex flex-1 flex-col justify-between items-center">
            <HRef
              style={{
                alignSelf: 'center',
                fontStyle: 'italic',
                textAlign: 'center',
              }}
              href="https://my.ionnyk.com/forgot-password"
              target="_blank"
            >
              {t('signin.forgot')}
            </HRef>
            <HRef
              style={{
                alignSelf: 'center',
                textAlign: 'right',
                fontStyle: 'italic',
              }}
              href={'https://support.ionnyk.com'}
            >
              {t('signin.contact_us')}
            </HRef>
          </Col>
        </Row>
        <Row className="mt-3">
          <Footer versionName={ENV_VARS.VITE_PORTAL_VERSION} />
        </Row>
      </GridContainer>
    </MainFlexContainer>
  );
};
