import { FlexContainer } from '@/components/FlexContainer';
import { MainFlexContainer } from '@/components/MainFlexContainer';
import React, { useState } from 'react';
import { DispatchList } from './List';
import { useEntitiesLoader } from '@/hooks/useEntitiesLoader';
import { fetchScheduledDispatches } from '@/features-slice/dispatch/scheduledDispatchSlice';
import { useTranslation } from '@/i18n/useTranslation';

interface Props {
  children: React.ReactNode;
}
export const Planning = (p: Props) => {
  const { loading, items, entities } = useEntitiesLoader({
    rootEntitiesSelector: (s) => s.dispatches,
    loaderThunk: fetchScheduledDispatches,
  });
  const [dispatchUuid, setDispatchUuid] = useState<string>(null);
  const t = useTranslation();
  return (
    <MainFlexContainer className="">
      <FlexContainer title={t('dispatch.title')} loading={loading} className="">
        <DispatchList
          className="flex-1 w-full shadow-2xl"
          dispatches={entities}
          // selectedDispatchUuid={dispatchUuid}
          // onSelectedDispatch={(dispatchUuid: string) => {
          //   setDispatchUuid(dispatchUuid);
          // }}
        />
      </FlexContainer>
    </MainFlexContainer>
  );
};
