import React, { ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { useTranslation } from '@/i18n/useTranslation';

interface Props extends ComponentPropsWithRef<'div'> {
  map: Record<string, string>;
}

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    // @ts-ignore
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'black',//theme.palette.common.black,
    color: 'white',//theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const KVTable = (p: Props) => {
  const { className, ...rest } = p;
  const t = useTranslation();
  return (
    <TableContainer className="overflow-x-auto">
      <Table className='shadow-md'>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{t('key')}</StyledTableCell>
            <StyledTableCell>{t('value')}</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {Object.entries(p.map).map(([k, v]) => (
            <StyledTableRow key={k}>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>{k}</StyledTableCell>
              <StyledTableCell>{v}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
