import { ComponentPropsWithRef, useState } from 'react';
import { Box, CircularProgress, Link, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../components/Button/index';
import { Icons } from '@/components/icons';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { useTranslation } from '@/i18n/useTranslation';
import { MFASession } from '@/api-services/firebase';

type Props = {
  onClose: () => void;
  onSubmit: (data: string) => Promise<void>;
  session: Partial<MFASession>;
};
export const MFAModal = (p: Props) => {
  const { onClose, onSubmit, session } = p;
  const { stage } = session ?? {};

  const [state, setState] = useState({
    data: '',
    loading: false,
  });

  const t = useTranslation();

  return (
    <Dialog
      open={!!session}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle sx={{ mr: 2, p: 2 }} id="customized-dialog-title">
        {t('mfamodal.title')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers className="p-8">
        {stage === 'CODE' && (
          <Box className="flex flex-col justify-center items-stretch gap-4">
            <Typography variant="h6">{t('mfamodal.set_code')}</Typography>
            <Typography variant="body1">{session?.phoneFactor?.phoneNumber}</Typography>
            <TextField
              autoFocus
              label="Code"
              type="number"
              value={state.data}
              onChange={(e) => setState({ ...state, data: e.target.value })}
            />
            <br />
            {session?.phoneFactor?.phoneNumber && (
              <Link href="mailto:support@ionnyk.com" onClick={async () => console.info}>
                {t('mfamodal.hint.support')}
              </Link>
            )}
          </Box>
        )}
        {stage === 'PHONE' && (
          <Box className="flex flex-col justify-center items-stretch gap-4">
            <Typography variant="h6">{t('mfamodal.set_phone')}</Typography>
            <TextField
              autoFocus
              helperText={t('mfamodal.hint.prefix')}
              label="Phone"
              type="tel"
              value={state.data}
              onChange={(e) => setState({ ...state, data: e.target.value })}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className="w-3/4 mx-auto"
          disabled={state.loading}
          autoFocus
          onClick={async () => {
            setState({ ...state, loading: true });
            try {
              await onSubmit(state.data);
            } catch (e) {
              console.warn('MFAModal', e);
              if ('errorKey' in e) {
                alert(t(e.errorKey));
              } else {
                alert(e.message);
              }
            }
            setState({ ...state, loading: false });
          }}
        >
          {state.loading ? (
            <CircularProgress className="ml-2" size="1rem" />
          ) : (
            <Icons.Next fontSize="small" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
