import { ComponentPropsWithRef, useMemo, useState } from 'react';
import { FlexContainer } from '@/components/FlexContainer';
import { BatchPreview } from './Preview';
import { AtwFilters } from './AtwFilters';
import { Divider, Typography } from '@mui/material';
import { BatchResponse } from '@/features-slice/batch/batchDTO';
import {
  ALL_FRAME_MODELS,
  TFrameModel,
  ALL_FRAME_ORIENTATIONS,
  TFrameOrientation,
} from '@ionnyk-npm/common-ts';
import { ArtworkDTO } from '@/features-slice/artworks/ArtworkDTO';
import { browserAppLang, useTranslation } from '@/i18n/useTranslation';
import { H1 } from '@/components/H1';
export type FiltersType = { orientation: TFrameOrientation | ''; frameModel: TFrameModel | '' };

interface Props extends ComponentPropsWithRef<'div'> {
  targetBatch: BatchResponse;
  artworks: ArtworkDTO[];
  loading: boolean;
}
export const BatchDetail = (p: Props) => {
  const { className, targetBatch, loading, artworks, ...rest } = p;
  const [filters, setFilters] = useState<FiltersType>({
    orientation: '',
    frameModel: '',
  });
  const filteredArtworks = useMemo(() => {
    if (!artworks) {
      return [];
    }
    console.log('filteredArtworks', filters);
    return artworks.filter((atw) => {
      if (filters.frameModel && atw.compatibleFrameModels[0] !== filters.frameModel) {
        return false;
      }
      if (filters.orientation && atw.orientation !== filters.orientation) {
        return false;
      }
      return true;
    });
  }, [artworks, filters]);

  const t = useTranslation();

  if(!targetBatch) {
    return <FlexContainer loading={loading} className='justify-center items-center'>
      <H1>{loading ? t('loading') : t('batch.noselection')}</H1>
      </FlexContainer>;
  }

  return (
    <FlexContainer
      title={`${targetBatch?.name?.[browserAppLang] ?? targetBatch?.name?.en}`}
      loading={loading}
    >
      <div className="flex flex-row w-full items-center space-x-4 p-2">
        <AtwFilters
          value={filters.frameModel}
          keys={[...ALL_FRAME_MODELS, '']}
          mapping={{
            '':  t('ALL'),
          }}
          onChange={(e, value) => {
            console.log('onChange', value, e);
            setFilters((prev) => ({ ...prev, frameModel: value as TFrameModel }));
          }}
        />
        <AtwFilters
          value={filters.orientation}
          keys={[...ALL_FRAME_ORIENTATIONS, '']}
          mapping={{
            '': t('ALL'),
          }}
          onChange={(e, value) => {
            console.log('onChange', value, e);
            setFilters((prev) => ({ ...prev, orientation: value as TFrameOrientation }));
          }}
        />
      </div>

      <Divider flexItem className="my-2">
        <Typography variant="caption">{`${t('batch.results')}: ${filteredArtworks.length}/${
          targetBatch?.numberOfArtworks ?? 0
        }`}</Typography>
      </Divider>
      <BatchPreview artworks={filteredArtworks} />
    </FlexContainer>
  );
};
