import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { store } from '@/stores/store';
import { RouterProvider } from 'react-router-dom';
import { router } from '@/pages/routes';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation'; //

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

// Change the target container for Portal-related elements so that they are injected into the root element.
const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
  palette: {
    primary: {
      main: '#222222',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#666666',
      // dark: will be calculated from palette.secondary.main,
    },
    success: { main: '#333333' },
    error: { main: '#434343' }, // 'no danger'
    warning: { main: '#5f5f5f' },
    info: { main: '#515151' },
  },
});

{
  /* Your component tree as children. Now you can override Material UI's styles. */
}
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);
