import { useEffect, useRef } from 'react';

/**
 * FIXME: to common
 * Dan Abrahamov's setInterval hooks compatible with hooks philosophy
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * @param callback
 * @param delay
 */
type CallableFn = () => void;
export function useInterval(callback: CallableFn, delay: number) {
  const savedCallback = useRef<CallableFn>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null && savedCallback.current) {
      savedCallback.current(); // call immediatel!
      const id = setInterval(savedCallback.current, delay);
      return () => {
        // when callack or delay changes
        clearInterval(id)
      };
    }
  }, [delay, callback]);
}
