import React, { ComponentPropsWithRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button as BTN } from '@mui/material';

interface Props extends ComponentPropsWithRef<typeof BTN> {
  // children: React.ReactNode;
}
export const Button = (p: Props) => {
  const { className, ...rest } = p;
  return (
    <BTN size="large" variant="contained" className={className} {...rest}>
      {p.children}
    </BTN>
  );
};
