import React, { ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends ComponentPropsWithRef<'h2'> {}
export const H2 = (p: Props) => {
  const { className, ...rest } = p;
  return <h2 className={
    twMerge('text-2xl', className)
  }>{p.children}</h2>;
};
