import React, { ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends ComponentPropsWithRef<'a'> {}
export const HRef = (p: Props) => {
  const { className: cz, ...rest } = p;
  return (
    <a className={twMerge(cz, 'text-neutral-700 hover:text-neutral-900 underline')} {...rest}>
      {p.children}
    </a>
  );
};
