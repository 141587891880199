import { createBrowserRouter, Outlet } from 'react-router-dom';
import { map2routesList, NO_ORDER } from '@ionnyk-npm/common-ts';
import { App } from '../features-slice/app/components/App';
import Error from '../features-slice/app/components/Error';
import { SignIn } from './SignIn/SignIn';
import { PublicLoading } from './PublicLoading';
import { RequireAuth } from '@/features-slice/auth/components/RequireAuth';
import { MainFlexContainer } from '@/components/MainFlexContainer';
import { Network } from './Network';
import { Batch } from './Batch';
import { Planning } from './Planning';
import { getDefaultTranslator } from '@/i18n/useTranslation';
import { Profile } from './Profile';

const t = getDefaultTranslator;
export const routesMap = {
  root: {
    key: 'root',
    label: t('profile.title'),
    path: '/',
    Component: Profile,
    order: 0,
    inMenu: false,
  },
  planning: {
    key: 'planning',
    label: t('dispatch.title'),
    path: '/planning',
    // path: '/planning/:id',
    Component: Planning,
    order: 1,
    inMenu: true,
  },
  batch: {
    key: 'batch',
    label: t('batch.title'),
    path: '/batch',
    // path: '/batch/:id',
    Component: Batch,
    order: 2,
    inMenu: true,
  },
  network: {
    key: 'network',
    label: t('network.title'),
    path: '/network',
    Component: Network,
    order: 3,
    inMenu: true,
  },
  network_detail: {
    key: 'network_detail',
    label: 'Network Detail',
    path: '/network/:uuid',
    Component: Network,
    order: NO_ORDER,
    inMenu: false,
  },
  login: {
    key: 'login',
    label: 'login',
    path: '/login',
    Component: SignIn,
    order: NO_ORDER,
    public: true,
  },
  loading: {
    key: 'loading',
    label: 'Loading',
    path: '/loading',
    Component: PublicLoading,
    order: NO_ORDER,
    public: true,
  },
  '404': {
    key: '404',
    label: '404',
    path: '*',
    Component: () => <MainFlexContainer>404</MainFlexContainer>,
    public: true,
    order: NO_ORDER,
  },
} as const;

export const allRoutes = map2routesList(routesMap);
export const publicRoutes = map2routesList(routesMap, true);
export const inMenuRoutes = allRoutes.filter((r) => r.inMenu);

export const router = createBrowserRouter([
  {
    path: routesMap.root.path, // mandatory root of all Outlet subpath '/' - FIXME Profile? = top children = Tab1
    element: (
      <App>
        <Outlet />
      </App>
    ),
    errorElement: <Error />,
    children: map2routesList(routesMap).map((r, i) => ({
      // index: i === 0 ? true : undefined,
      path: r.path,
      element: r.public ? (
        <r.Component />
      ) : (
        <RequireAuth>
          <r.Component />
        </RequireAuth>
      ),
    })),
  },
]);
