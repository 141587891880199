import React, { ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends ComponentPropsWithRef<'div'> {
  direction?: 'row' | 'col';
}

export const MainFlexContainer = (p: Props) => {
  const { className, direction = 'col', ...rest } = p;
  return (
    <main className={twMerge('m-0 flex flex-1 flex-col w-full bg-light', className)} {...rest}>
      <div
        className={`flex flex-${direction} items-center justify-center w-full h-full px-0 mx-auto mt-0 ml-0`}
      >
        {p.children}
      </div>
    </main>
  );
};
