import {
  CLIENT_BAD_REQUEST_STATUS,
  CLIENT_ERROR,
  FORBIDDEN_STATUS,
  NETWORK_ERROR,
  NOT_FOUND_STATUS,
  SERVER_ERROR_STATUS,
  TIMEOUT_ERROR_KEY,
  UNAUTHORIZED_STATUS,
} from '@/utils/toCommon';
import { TKeys } from './en';
import { UNKNOWN_ERROR_KEY, CLIENT_BAD_REQUEST_STATUS_STRING } from '@ionnyk-npm/common-ts';
import { MFA_REQUIRED, MISSING_USER_ERROR, WRONG_PASSWORD, EMAIL_NOT_VERIFIED, RELOGIN_REQUIRED } from '@/api-services/AuthError';

export const fr: Record<TKeys, string> = {
  group: 'Groupe',
  groups: 'Groupes',
  group_name: 'Nom du groupe',
  subgroups: 'Sous-groupes',
  parent: 'Parent',
  parent_group: 'Groupe parent',
  frame: 'Cadre',
  frames: 'cadres',
  loading: 'Chargement ...',
  btn_add: 'Ajouter',
  btn_update: 'Mettre à jour',
  yes: 'Oui',
  no: 'Non',
  notyet: 'Pas encore',
  key: 'Champ',
  value: 'Valeur',
  'signin.contact_us': 'Contactez-nous',
  'signin.title': 'Veuillez vous identifier',
  'signin.psw': 'Mot de passe',
  'signin.email': 'E-mail',
  'signin.btn': 'Connexion',
  btn_logout: 'Déconnexion',
  'signin.forgot': 'Mot de passe oublié',
  'signup.btn': "S'inscrire",
  'profile.title': 'Compte',
  'profile.subtitle': 'Informations sur le compte',
  'network.manager': 'Gestionnaire de réseau',
  'network.rename_group': 'Renommer le groupe',
  'network.add_subgroup': 'Ajouter un sous-groupe',
  'network.pairing': 'Cadre',
  'network.pairings': 'Cadres',
  'network.name': 'Nom',
  'network.model': 'Modèle',
  'network.orientation': 'Orientation',
  'network.battery': 'Batterie',
  'network.online': 'Connecté',
  'network.online.hint': 'Connecté dans les dernières',
  ONLINE: 'Connecté',
  'network.offline': 'Hors ligne/En veille',
  'network.offline.hint': 'Le cadre est hors ligne depuis',
  OFFLINE: 'Hors ligne/En veille', //FIXME
  'batch.noselection': 'Aucune sélection',
  'batch.results': 'Résultats',
  'batch.success': 'Collection planifiée avec succès!',
  'batch.name': 'Nom',
  'batch.creationdate': 'Créé',
  'batch.total': 'Total',
  'batch.startdate': 'Date de début',
  'batch.enddate': 'Date de fin',
  'batch.modal.title': 'Propager la collection',
  'dispatch.cancel': 'Annuler',
  'dispatch.status': 'Statut',
  'dispatch.created': 'Créé',
  'dispatch.batch': 'Collection',
  'dispatch.start': 'Début',
  'dispatch.end': 'Fin',
  'dispatch.offline': 'Hors ligne',
  'error.title': 'Oups!',
  'error.subtitle': "Désolé, une erreur inattendue s'est produite.",
  'search.placeholder': 'Recherche',
  'search.results': 'résultat(s) trouvé(s)',
  SCHEDULED: 'PLANIFIÉ',
  ACTIVE: 'ACTIF',
  DONE: 'TERMINÉ',
  IN_ERROR: 'EN ERREUR',
  CANCELED: 'ANNULÉ',
  'dispatch.title': 'Planification',
  'network.title': 'Réseau',
  'batch.title': 'Collections',
  HORIZONTAL: 'PAYSAGE',
  VERTICAL: 'PORTRAIT',
  ALL: 'TOUT',
  // errors
  [UNKNOWN_ERROR_KEY]: 'Erreur inconnue',
  [CLIENT_ERROR]: 'Veuillez vérifier vos données',
  [TIMEOUT_ERROR_KEY]: 'Requête réseau trop longue',
  [NETWORK_ERROR]: 'Erreur réseau',
  [CLIENT_BAD_REQUEST_STATUS_STRING]: 'Requête invalide',
  [NOT_FOUND_STATUS]: 'Non trouvé',
  [SERVER_ERROR_STATUS]: 'Erreur serveur inconnue',
  [CLIENT_BAD_REQUEST_STATUS]: 'Requête invalide',
  [UNAUTHORIZED_STATUS]: 'Identifiant(s) invalide(s)',
  [FORBIDDEN_STATUS]: 'Accès refusé',
  [MFA_REQUIRED]: 'Veuillez compléter le processus 2FA',
  [MISSING_USER_ERROR]: 'Une erreur est survenue lors de la configuration du 2FA',
  [WRONG_PASSWORD]: 'Veuillez vérifier votre mot de passe',
  [EMAIL_NOT_VERIFIED]: 'Veuillez vérifier votre  email (lien envoyé)',
  [RELOGIN_REQUIRED]: 'Veuillez vous déconnecter et vous reconnecter pour vous assurer que votre session soit à jour',
  'mfamodal.title': '2FA - Authentification à deux facteurs',
  'mfamodal.set_code': 'Veuillez entrer le code reçu par SMS',
  'mfamodal.set_phone': 'Veuillez entrer votre numéro de téléphone',
  'mfamodal.hint.support': 'Ce n\'est pas votre téléphone?',
  'mfamodal.hint.prefix': 'Veuillez commencer par le code pays +XX',
  'profile.add_2fa': 'Ajouter le 2FA (SMS)',
  'profile.read_2fa': '2FA configuré pour',
  'profile.add_2fa.success': '2FA configuré avec succès',
};
