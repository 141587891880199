import { Fragment, useMemo } from 'react';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useExpanded,
  Row as RowRT,
  UseExpandedRowProps,
} from 'react-table';
import { FlexContainer } from '@/components/FlexContainer';
import { DecoratedPairing, PairingDTO } from '@/features-slice/pairings/PairingDTO';
import { DecoratedGroup } from '@/features-slice/group/GroupSlice';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { H2 } from '@/components/H2';
import { AtwCardWrapper } from './AtwCardWrapper';
import { B2BGlobalFilter, StyledTableRow } from '@/utils/tables';
import { initialPairingState, pairingColumns } from './columns';
import { useTranslation } from '@/i18n/useTranslation';
import { isPairingOffline } from './utils';

type Props = {
  loading: boolean;
  targetGroup: DecoratedGroup;
};
const NetworkListComp = (p: Props) => {
  const { loading, targetGroup } = p;
  const data = useMemo(
    () =>
      // @ts-ignore
      targetGroup?.pairings?.toSorted(
        (p1: DecoratedPairing, p2: DecoratedPairing) => {
          // return p1.name.localeCompare(p2.name);
          return (p2.batteryLevel ?? 0.0) -  (p1.batteryLevel??0.0);
        }
      ) ?? [],
    [targetGroup?.pairings]
  );
  const alteredColumns = useMemo(() => [
    ...pairingColumns,
  ], []);

  const t = useTranslation();
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    // @ts-ignore
    preGlobalFilteredRows,
    // @ts-ignore
    setGlobalFilter,
  } = useTable<Partial<DecoratedPairing>>(
    {
      columns: alteredColumns,
      data,
      // @ts-ignore FIXME ts errors
      autoResetExpanded: false, // prevent when data change
      // @ts-ignore
      initialState: initialPairingState,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded
  );

  return (
    <FlexContainer loading={loading}>
      <H2>
        {`${t('network.pairings')}`}
        {loading && <CircularProgress className="ml-2" size="1rem" />}
      </H2>
      <B2BGlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        // @ts-ignore
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      {/* TABLE container */}
      <Table {...getTableProps()} className="table table-zebra table-pin-rows table-pin-cols">
        <TableHead>
          {headerGroups.map((headerPairingDTO, i) => (
            <TableRow key={i} {...headerPairingDTO.getHeaderGroupProps()}>
              {headerPairingDTO.headers.map((column, j) => {
                // @ts-ignore
                const toggle = column.getSortByToggleProps();
                return (
                  <TableCell
                    className="border bg-neutral-400 font-bold"
                    key={j}
                    {...column.getHeaderProps(toggle)}
                  >
                    {column.render('Header')}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody className="overflow-y-scroll">
          {rows.map((row: RowRT<DecoratedPairing> & UseExpandedRowProps<DecoratedPairing>, i) => {
            prepareRow(row);
            const pairing = row.original as DecoratedPairing;
            return (
              <Fragment key={pairing.pairingUuid}>
                <StyledTableRow
                  className="hover"
                  key={i}
                  {...row.getRowProps()}
                  {...row.getToggleRowExpandedProps()}
                >
                  {row.cells.map((cell, j) => {
                    return (
                      <TableCell key={j} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
                {row.isExpanded ? (
                  <TableRow className="bg-neutral-100 text-black text-xs">
                    <TableCell
                      colSpan={visibleColumns.length}
                      className="w-full"
                      title={isPairingOffline(pairing) ? `⚠️ ${t('network.offline')}` : ''}
                    >
                      <AtwCardWrapper
                        className={isPairingOffline(pairing) ? 'opacity-50' : 'opacity-100'}
                        uuid={pairing.currentArtwork}
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  <div />
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </FlexContainer>
  );
};

// @ts-ignore
export const NetworkList = NetworkListComp;
