import React, { useCallback, useState } from 'react';
import { MainFlexContainer } from '@/components/MainFlexContainer';
import { useAppSelector } from '@/stores/useReduxTK';
import { GroupHierarchy } from './GroupHierarchy';
import { NetworkList } from './NetworkList';
import { useDecoratedGroupPoller } from '@/hooks/useDecoratedGroup';

interface Props {
  children: React.ReactNode;
}
export const Network = (p: Props) => {
  // FIXME expand sub-group from params
  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const urlParams = useParams(); //OK working
  // const uuid = urlParams.uuid || myGroupUuid;

  // FIXME allow to select subgroup
  const { group: rootGroup } = useAppSelector((state) => state.login);
  const rootDecorated = useAppSelector((s) => s.group.items[rootGroup.uuid]);

  const [selectedUuid, setSelectedUuid] = useState(rootGroup.uuid);

  const { decoratedGroup: selectedDecorated, loaded: selectedLoaded } = useDecoratedGroupPoller(
    selectedUuid,
    30000 // 30s, FIXME arbitrary
  );

  const onSelect = useCallback((groupUuid: string) => setSelectedUuid(groupUuid), [setSelectedUuid]);

  return (
    <MainFlexContainer direction="row">
      {/* FIXME recursive */}
      {!rootDecorated && 'empty root group...'}
      {rootDecorated && (
        <GroupHierarchy
          isRoot
          parentGroup={rootDecorated}
          onSelect={onSelect}
          selectedUuid={selectedUuid}
        />
      )}
      <NetworkList loading={!selectedLoaded} targetGroup={selectedDecorated} />
    </MainFlexContainer>
  );
};
