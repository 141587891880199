import { useLocation, Navigate } from 'react-router-dom';
import { routesMap } from '@/pages/routes';
import { useAppSelector } from '@/stores/useReduxTK';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const loggedIn = useAppSelector((state) => state.login.loggedIn);
  const location = useLocation();

  if (!loggedIn) {
    console.log(
      'RequireAuth: not logged in, redirecting to login page',
      routesMap.login.path,
      location
    );
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate state={{ from: location }} to={routesMap.login.path} replace />;
  }

  return children;
};
