import { MainFlexContainer } from '@/components/MainFlexContainer';
import React, { useEffect, useMemo, useState } from 'react';
import { BatchList } from './List';
import { useAppDispatch, useAppSelector } from '@/stores/useReduxTK';
import { fetchBatch, fetchBatches } from '@/features-slice/batch/batchSlice';
import { BatchDetail } from './Detail';
import { postScheduledDispatches } from '@/features-slice/dispatch/scheduledDispatchSlice';

interface Props {
  children: React.ReactNode;
}

export const Batch = (p: Props) => {
  const [selectedBatch, setSelectedBatch] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const batchesMap = useAppSelector((state) => state.batches.items);
  const batches = useMemo(() => Object.values(batchesMap), [batchesMap]);
  const targetBatch = selectedBatch in batchesMap ? batchesMap[selectedBatch] : null;

  const atwMap = useAppSelector((state) => state.artworks.items);
  const artworks = useMemo(
    () => targetBatch?.artworksUUIDs?.map((uuid) => atwMap[uuid]),
    [atwMap, targetBatch?.artworksUUIDs]
  );

  const batchLoading = useAppSelector((state) => state.batches.loading);
  const atwLoading = useAppSelector((state) => state.artworks.loading);
  const loading = batchLoading || atwLoading;

  useEffect(() => {
    if (!batches.length) {
      dispatch(fetchBatches());
    }
  }, [batches.length, dispatch]);

  useEffect(() => {
    if (!targetBatch?.artworksUUIDs?.length && !artworks?.length && selectedBatch) {
      dispatch(fetchBatch(selectedBatch));
    }
  }, [artworks?.length, batches, dispatch, selectedBatch, targetBatch?.artworksUUIDs?.length]);

  return (
    <MainFlexContainer direction="row" className="h-screen">
      <BatchList
        className="h-screen border-r-1 border-y-0 border-l-0 border-solid border-primary"
        batches={batches}
        onSelectedBatch={(batchUuid: string) => {
          console.log('onSelectedBatch', batchUuid);
          setSelectedBatch(batchUuid);
        }}
        onPlayBatch={(batchUuid: string, groupUuid: string, startDate: Date) =>
          dispatch(
            postScheduledDispatches({
              batchUuid,
              groupUuid,
              startDispatchMoment: startDate.toISOString(),
            })
          )
        }
        selectedBatchUuid={selectedBatch}
      />
      <BatchDetail targetBatch={targetBatch} artworks={artworks} loading={loading} />
    </MainFlexContainer>
  );
};
