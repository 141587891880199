import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  APIReject,
  Group,
  ItemsResponse,
  initialRequestStatus,
  handleNetwError,
  setLoadingReducer,
  setErrorReducer,
} from '@ionnyk-npm/common-ts';
import { authGroupApi } from '@/api-services/api';
import { DecoratedPairing } from '../pairings/PairingDTO';
import { UserDTO } from '../users/UserDTO';
import { fetchPairings } from '../pairings/PairingSlice';
import { fetchUser, fetchUsers } from '../users/UserSlice';
import { addGenericCase, makeThunk, privateAddEntity } from '@/utils/toCommon';
import _ from 'lodash';
import { loginAsync } from '../auth/slice/signinSlice';

/**
 * ======= ACTIONS (ASYNC) ======
 */

export type DecoratedGroup = Group & {
  uuid: string;
  parent: string | null;
  pairings?: DecoratedPairing[];
  users?: UserDTO[]; // FIXME directMembersUuuid
  children?: Group[];
  parentGroup?: Group;
};
export const fetchGroups = createAsyncThunk<
  ItemsResponse<Group>, // output
  void, // input
  APIReject // error output
>('fetchGroups', async (_: any, thunkAPI) => {
  try {
    // FIXME pagination
    return (await authGroupApi.get(`/groups?page=0&size=99999`))?.data as ItemsResponse<Group>;
  } catch (e) {
    return thunkAPI.rejectWithValue(handleNetwError('fetchGroups', e, null, [], true));
  }
});

export const fetchGroup = createAsyncThunk<
  Group, // output
  string, // input
  APIReject // error output
>('fetchGroup', async (uuid: string, thunkAPI) => {
  try {
    const response = (await authGroupApi.get(`/groups/${uuid}`))?.data;
    return response as Group;
  } catch (e) {
    return thunkAPI.rejectWithValue(handleNetwError('fetchGroup', e, null, [], true));
  }
});

export const fetchChildren = createAsyncThunk<
  Group[], // output
  string, // input
  APIReject // error output
>('fetchChildren', async (uuid: string, thunkAPI) => {
  try {
    const response = (await authGroupApi.get(`/groups/${uuid}/children-groups`))?.data ?? [];
    return response as Group[];
  } catch (e) {
    return thunkAPI.rejectWithValue(handleNetwError('fetchChildren', e, null, [], true));
  }
});

export const fetchParent = makeThunk<Group, Pick<Group, 'uuid' | 'parent'>>(
  'fetchParent',
  (group) => authGroupApi.get(`/groups/${group.parent}`)
);

// FIXME bugs api server
export type UpdateGroupData = Omit<Group, 'directMembersUuids' | 'directMembersIds'>;
export const updateGroup = makeThunk<Group, UpdateGroupData>('updateGroup', (data) =>
  authGroupApi.put(`/groups/${data.uuid}`, _.omit(data, 'uuid'))
);
export const createGroup = makeThunk<Group, Omit<UpdateGroupData, 'uuid'>>('createGroup', (data) =>
  authGroupApi.post(`/groups`, data)
);

export const isInitialised = (state: any) => ['SUCCESS', 'INITIAL'].includes(state.initialLoading);
export type TLoadingStatus = 'BLANK' | 'INITIAL' | 'LOADING' | 'ERROR' | 'SUCCESS'; // FIXME common
const initialState = {
  total: 0,
  initialLoading: 'BLANK' as TLoadingStatus,
  items: {} as Record<string, DecoratedGroup>,
  ...initialRequestStatus,
};

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    addMembers: (state, action) => {
      // FIXME
    },
    setParentGroup: (state, action) => {
      // FIXME
    },
  },
  extraReducers: (bd) => {
    addGenericCase(bd, fetchGroup, (state, action) => {
      const group = action.payload;
      if (!(group.uuid in state.items)) {
        state.items[group.uuid] = group;
        state.total++;
      }
      state.loading = false;
    });

    addGenericCase(bd, fetchChildren, (state, action) => {
      const groups = action.payload;
      for (const g of groups) {
        privateAddEntity(state, g);
      }
      const parentUuid = action.meta.arg;
      const parentGroup = state.items?.[parentUuid];
      if(parentGroup) {
        parentGroup.children = groups;
      }
      state.loading = false;
    });

    addGenericCase(bd, loginAsync, (state, action) => {
      console.log('loginasycn add group');
      const { group } = action.payload;
      privateAddEntity(state, group);
      state.loading = false;
    });

    addGenericCase(bd, fetchParent, (state, action) => {
      const parentGroup = action.payload;
      if (!(parentGroup.uuid in state.items)) {
        state.items[parentGroup.uuid] = parentGroup;
        state.total++;
      }

      const { uuid, parent } = action.meta.arg;
      const previousGroup = state.items?.[uuid];
      if (!previousGroup) {
        // @ts-ignore
        state.items[uuid] = {
          // FIXME should never happen
          uuid,
          parentGroup,
          parent,
        };
      } else {
        state.items[uuid]['parentGroup'] = parentGroup;
      }

      state.loading = false;
    });

    bd.addCase(fetchUser.fulfilled, (state, action) => {
      const user = action.payload;
      const group = user?.group;
      const previousGroup = state.items?.[group.uuid];
      state.items[group.uuid] = {
        ...previousGroup,
        ...group,
      };
    });


    bd.addCase(createGroup.pending, setLoadingReducer);
    bd.addCase(createGroup.rejected, setErrorReducer);
    bd.addCase(createGroup.fulfilled, (state, action) => {
      const group = action.payload;
      state.items[group.uuid] = { ...state.items[group.uuid], ...group };
      state.loading = false;
    });
    //
    bd.addCase(updateGroup.pending, setLoadingReducer);
    bd.addCase(updateGroup.rejected, setErrorReducer);
    bd.addCase(updateGroup.fulfilled, (state, action) => {
      const group = action.payload;
      state.items[group.uuid] = { ...state.items[group.uuid], ...group };
      state.loading = false;
    });
    //
    bd.addCase(fetchGroups.pending, setLoadingReducer);
    bd.addCase(fetchGroups.rejected, setErrorReducer);
    bd.addCase(fetchGroups.fulfilled, (state, action) => {
      state.initialLoading = state.initialLoading === 'BLANK' ? 'INITIAL' : 'SUCCESS'; // FIXME
      
      action.payload.items.forEach((g) => {
        privateAddEntity(state, g); // merge ! 
      });
      
      // state.total = action.payload.total; // FIXME numberOfItems
      // state.items = array2Map(action.payload.items, (g) => g.uuid); // replace!
      state.loading = false;
    });
    // pairings
    bd.addCase(fetchPairings.pending, setLoadingReducer);
    bd.addCase(fetchPairings.rejected, setErrorReducer);
    bd.addCase(fetchPairings.fulfilled, (state, action) => {
      const pairings = action.payload;
      const groupUuid = action.meta.arg;
      const previousGroup = state.items?.[groupUuid];
      if (!previousGroup) {
        // @ts-ignore
        state.items[groupUuid] = {
          // FIXME should never happen
          uuid: groupUuid,
          pairings,
        };
      } else {
        state.items[groupUuid]['pairings'] = pairings;
      }
      state.loading = false;
    });
    // users
    bd.addCase(fetchUsers.pending, setLoadingReducer);
    bd.addCase(fetchUsers.rejected, setErrorReducer);
    bd.addCase(fetchUsers.fulfilled, (state, action) => {
      const users = action.payload;
      const groupUuid = action.meta.arg;
      const previousGroup = state.items?.[groupUuid];
      if (!previousGroup) {
        // @ts-ignore
        state.items[groupUuid] = {
          // FIXME should never happen
          uuid: groupUuid,
          users,
        };
      } else {
        state.items[groupUuid]['users'] = users;
      }
      state.loading = false;
    });
  },
});
export const { addMembers, setParentGroup } = groupSlice.actions;
