import Send from '@mui/icons-material/Send'
import Build from '@mui/icons-material/Build'
import Top from '@mui/icons-material/ArrowUpward'
import Bottom from '@mui/icons-material/ArrowDownward'
import Back from '@mui/icons-material/ArrowBack'
import Next from '@mui/icons-material/ArrowForward'
import Menu from '@mui/icons-material/Menu'
import Power from '@mui/icons-material/PowerSettingsNewTwoTone'
import More from '@mui/icons-material/MoreVert'
import Reset from '@mui/icons-material/StopCircleOutlined'
import DragIndicator from '@mui/icons-material/DragIndicator';
import LockOutlined from '@mui/icons-material/LockOutlined'
import Logout from '@mui/icons-material/Logout';
import Edit from '@mui/icons-material/Edit';
import Undo from '@mui/icons-material/Undo';
import Delete from '@mui/icons-material/Delete';
import PlayArrow from '@mui/icons-material/RepeatOutlined';
import Stop from '@mui/icons-material/StopCircleOutlined';
import Help from '@mui/icons-material/HelpOutline';
import WifiOff from '@mui/icons-material/WifiOff';
import WifiOn from '@mui/icons-material/Wifi';
import Landscape from '@mui/icons-material/CropLandscape';
import Portrait from '@mui/icons-material/CropPortrait';
import Search from '@mui/icons-material/Search';

// https://mui.com/material-ui/material-icons/?query=battery&selected=BatteryAlert
import BatteryAlert from '@mui/icons-material/BatteryAlert';
import Battery0Bar from '@mui/icons-material/Battery0Bar';
import Battery1Bar from '@mui/icons-material/Battery1Bar';
import Battery2Bar from '@mui/icons-material/Battery2Bar';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import Battery4Bar from '@mui/icons-material/Battery4Bar';
import Battery5Bar from '@mui/icons-material/Battery5Bar';
import Battery6Bar from '@mui/icons-material/Battery6Bar';
import BatteryFull from '@mui/icons-material/BatteryFull';
import BatteryUnknown from '@mui/icons-material/BatteryUnknown';

export const Icons = {
    Send,
    Build,
    Back,
    Next,
    Top,
    Bottom,
    Menu,
    Power,
    More,
    Reset,
    DragIndicator,
    LockOutlined,
    Logout,
    Edit,
    Undo,
    Delete,
    PlayArrow,
    Stop,
    Help,
    WifiOff,
    WifiOn,
    Portrait,
    Landscape,
    Search,
    BatteryAlert,
    Battery0Bar,
    Battery1Bar,
    Battery2Bar,
    Battery3Bar,
    Battery4Bar,
    Battery5Bar,
    Battery6Bar,
    BatteryFull,
    BatteryUnknown
}