import { EMAIL_NOT_VERIFIED, MFA_REQUIRED, MISSING_USER_ERROR, RELOGIN_REQUIRED, WRONG_PASSWORD } from '@/api-services/AuthError';
import { CLIENT_BAD_REQUEST_STATUS, CLIENT_ERROR, FORBIDDEN_STATUS, NETWORK_ERROR, NOT_FOUND_STATUS, SERVER_ERROR_STATUS, TIMEOUT_ERROR_KEY, UNAUTHORIZED_STATUS,  } from '@/utils/toCommon';
import {
  UNKNOWN_ERROR_KEY,
  CLIENT_BAD_REQUEST_STATUS_STRING,
} from '@ionnyk-npm/common-ts';

export const en = {
  group: 'Group',
  groups: 'Groups',
  group_name: 'Group name',
  subgroups: 'Subgroups',
  parent: 'Parent',
  parent_group: 'Parent group',
  frame: 'Frame',
  frames: 'frames',
  loading: 'Loading ...',
  btn_add: 'Add',
  btn_update: 'Update',
  yes: 'Yes',
  no: 'No',
  notyet: 'Not yet',
  key: 'Key',
  value: 'Value',
  'signin.contact_us': 'Contact us',
  'signin.title': 'Please login',
  'signin.psw': 'Password',
  'signin.email': 'Email',
  'signin.btn': 'Login',
  btn_logout: 'Logout',
  'signin.forgot': 'Forgot password',
  'signup.btn': 'Signup',
  'profile.title': 'Account',
  'profile.subtitle': 'Account Information',
  'profile.add_2fa': 'Add 2FA',
  'profile.add_2fa.success': 'MFA successfully setup for your account',
  'profile.read_2fa': '2FA configured for',
  'mfamodal.title': '2FA challenge',
  'mfamodal.set_code': 'Please enter the code sent to your phone',
  'mfamodal.set_phone': 'Please enter your phone number',
  'mfamodal.hint.support': 'This is not your phone?',
  'mfamodal.hint.prefix': 'Please start with the +XX country code',
  'network.title': 'Network',
  'network.name': 'Name',
  'network.manager': 'Network Manager',
  'network.rename_group': 'Rename group',
  'network.add_subgroup': 'Add subgroup',
  'network.pairing': 'Pairing',
  'network.pairings': 'Pairings',
  'network.model': 'Model',
  'network.orientation': 'Orientation',
  'network.battery': 'Battery',
  'network.online': 'Online',
  'network.online.hint': 'ONLINE in the last',
  ONLINE: 'Online',
  'network.offline': 'Offline/Asleep',
  'network.offline.hint': 'The frame looks OFFLINE since ',
  OFFLINE: 'Offline/Asleep', //FIXME
  'batch.title': 'Collections',
  'batch.noselection': 'No collection selected',
  'batch.success': 'Collection successfully planned!',
  'batch.results': 'Results',
  'batch.name': 'Name',
  'batch.creationdate': 'Created',
  'batch.total': 'Total',
  'batch.startdate': 'Start date',
  'batch.enddate': 'End date',
  'batch.modal.title': 'Send collection to Network',
  'dispatch.title': 'Planning',
  'dispatch.cancel': 'Cancel',
  'dispatch.status': 'Status',
  'dispatch.created': 'Created',
  'dispatch.batch': 'Collection',
  'dispatch.start': 'Start',
  'dispatch.end': 'End',
  'dispatch.offline': 'Offline',
  'error.title': 'Oops!',
  'error.subtitle': 'Sorry, an unexpected error has occurred.',
  'search.placeholder': 'Search here',
  'search.results': 'result(s) found',
  SCHEDULED: 'SCHEDULED',
  ACTIVE: 'ACTIVE',
  DONE: 'DONE',
  IN_ERROR: 'IN_ERROR',
  CANCELED: 'CANCELED',
  HORIZONTAL: 'LANDSCAPE',
  VERTICAL: 'PORTRAIT',
  ALL: 'ALL',
  // errors
  [UNKNOWN_ERROR_KEY]: 'Unknown error',
  [CLIENT_ERROR]: 'Please verify your data',
  [TIMEOUT_ERROR_KEY]: 'Network timeout error',
  [NETWORK_ERROR]: 'Network error',
  [CLIENT_BAD_REQUEST_STATUS_STRING]: 'Bad request',
  [NOT_FOUND_STATUS]: 'Not found',
  [SERVER_ERROR_STATUS]: 'Unknown server error',
  [CLIENT_BAD_REQUEST_STATUS]: 'Invalid request',
  [UNAUTHORIZED_STATUS]: 'Invalid credential(s)',
  [FORBIDDEN_STATUS]: 'Forbidden access',
  // Firebase errors
  [MFA_REQUIRED]: 'Please complete the 2FA process',
  [MISSING_USER_ERROR]: 'There was an error when setuping 2FA',
  [WRONG_PASSWORD]: 'Please verifiy your password',
  [EMAIL_NOT_VERIFIED]: 'Please verifiy your email (link sent)',
  [RELOGIN_REQUIRED]: 'Please logout and re-login to ensure your session is up-to-date',
} as const;

export type TKeys = keyof typeof en;
