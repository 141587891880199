import React, { ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Hr } from '../Hr';
import { Button } from '../Button';
import Card from '@mui/material/Card';
import {
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Typography,
} from '@mui/material';

interface Props extends ComponentPropsWithRef<'div'> {
  title: string;
  subtitle?: string;
  footer?: string;
  lineDescription?: string[];
  buttonTxt?: string;
  buttonOnClick?: () => void;
  imageSrc?: string;
  subCard?: boolean;
}

export const LINE_PREFIX: string = '> ';
export const MCard = (p: Props) => {
  const { className: cz, ...rest } = p;
  return (
    <Card className={twMerge(`${p.subCard ? 'w-3/4' : 'w-96'} bg-base-100 shadow-xl`, cz)}>
      {p.title && <CardHeader title={p.title}  className='bg-black bg-opacity-20 shadow'/>}
      {p.imageSrc && <CardMedia sx={{ height: 140 }} image={p.imageSrc} title={p.title} />}
      <CardContent className='p-6'>
        {p.subtitle && (
          <>
            <Typography gutterBottom variant="h6" component="div">
              {p.subtitle}
            </Typography>
            <Divider />
          </>
        )}
        {p?.lineDescription?.map((d) => (
          <Typography
            key={d}
            variant="body2"
            color="text.secondary"
            className={`${d.startsWith(LINE_PREFIX) ? 'font-bold' : 'ml-3'}`}
          >
            {d}
          </Typography>
        ))}
        {p.children && p.children}
        {p.footer && (
          <Typography variant="h5" component="div">
            {p.footer}
          </Typography>
        )}
      </CardContent>
      {p.buttonTxt && (
        <CardActions disableSpacing={false} className='' >
          <Button className='w-full' onClick={p.buttonOnClick}>{p.buttonTxt}</Button>
        </CardActions>
      )}
    </Card>
  );
};
