import { appSlice } from '@/features-slice/app/appSlice';
import { artworksSlice } from '@/features-slice/artworks/ArtworksSlice';
import { signinSlice } from '@/features-slice/auth/slice/signinSlice';
import { batchesSlice } from '@/features-slice/batch/batchSlice';
import { dispatchesSlice } from '@/features-slice/dispatch/scheduledDispatchSlice';
import { groupSlice } from '@/features-slice/group/GroupSlice';
import { pairingsSlice } from '@/features-slice/pairings/PairingSlice';
import { usersSlice } from '@/features-slice/users/UserSlice';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Allowing some serialized data in the store;
      // cf. definition of APIErrorClient (toCommon.ts)
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['auth/login/rejected'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.originalError'],
        // Ignore these paths in the state
        ignoredPaths: ['login.serverError.originalError','group.serverError.originalError'],
      },
    }),
  // FIXME reducer file?
  reducer: {
    app: appSlice.reducer,
    login: signinSlice.reducer,
    group: groupSlice.reducer,
    users: usersSlice.reducer,
    pairings: pairingsSlice.reducer,
    artworks: artworksSlice.reducer,
    batches: batchesSlice.reducer,
    dispatches: dispatchesSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
