import React, { ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends ComponentPropsWithRef<'div'> {}
export const GridContainer = (p: Props) => {
  const { className, ...rest } = p;
  return (
    <div className={twMerge('p-5 grid ', className)}>
      {p.children}
    </div>
  );
};
