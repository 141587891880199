import { ComponentPropsWithRef } from 'react';
import { useEntityLoader } from '@/hooks/useEntityLoader';
import { RootState } from '@/stores/store';
import { fetchArtwork } from '@/features-slice/artworks/ArtworksSlice';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { formatPeopleFullName } from '@ionnyk-npm/common-ts';
import { useTranslation } from '@/i18n/useTranslation';

interface Props extends ComponentPropsWithRef<'div'> {
  uuid: string;
}
export const AtwCardWrapper = (p: Props) => {
  const { className, uuid } = p;

  const { loading, entity, serverError } = useEntityLoader({
    entityUuid: uuid,
    rootEntitySelector: (s: RootState) => s.artworks,
    loaderThunk: fetchArtwork,
  });

  const t = useTranslation();

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  if(serverError || !entity) {
    return <div className='italic'>{serverError?.desc}</div>;
  }

  const imgSrc = entity?.previews?.find((p) => p.label === 'MD')?.url;
  const subtitle = entity.artist ? formatPeopleFullName(entity.artist) : entity?.source?.displayName?.en;

  return (
    <Card className={`${className} w-fit`} sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
           {entity.title}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {subtitle}
          </Typography>
        </CardContent>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={imgSrc}
        alt={entity.title}
      />
    </Card>
  );
};
