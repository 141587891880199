import { adminApi, anonApi, authApi, authGroupApi } from '@/api-services/api';
import { MFARequiredError } from '@/api-services/AuthError';
import {
  finishMfaSignIn,
  firebaseRestoreSignin,
  firebaseSignIn,
  firebaseSignOut,
} from '@/api-services/firebase';
import { RootState } from '@/stores/store';
import {
  APIErrorClient,
  FORBIDDEN_STATUS,
  UNAUTHORIZED_STATUS,
  handleNetworkError,
  installBasicAuthHeader,
} from '@/utils/toCommon';
import {
  AuthState,
  initialRequestStatus,
  AuthUserDTOIn,
  UNKNOWN_ERROR_KEY,
  Group,
  setErrorReducer,
  setLoadingReducer,
  generateBasicToken,
} from '@ionnyk-npm/common-ts';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

type ExtendedAuthUserDTOIn = AuthUserDTOIn & {
  group: Group;
  roles: string[];
  firstName: string;
  lastName: string;
};

const initialState: Omit<AuthState, 'serverError'> &
  Partial<ExtendedAuthUserDTOIn> & {
    serverError?: APIErrorClient;
  } = {
  loggedIn: false,
  userId: -1,
  email: '',
  ...initialRequestStatus,
  serverError: undefined,
};

export type LoginArgs = {
  email?: string;
  password?: string;
};

const fetchUserData = async () => {
  console.log('fetchUserData ...');
  let userDTO = (await authApi.get(`authorization`))?.data;
  if (userDTO.uuid) {
    const myUser = (await authGroupApi.get(`/users/${userDTO.uuid}`))?.data;
    userDTO = {
      ...userDTO,
      ...myUser,
    };
  }
  return {
    ...userDTO,
    emailConfirmed: true,
    lastLoginDate: new Date().toJSON(),
  } as ExtendedAuthUserDTOIn;
};

export const loginAsync = createAsyncThunk<
  ExtendedAuthUserDTOIn, // output
  LoginArgs, // input
  { rejectValue: APIErrorClient } // error output
>('auth/login', async (args: LoginArgs, thunkAPI) => {
  const { email, password } = args;
  console.log('loginAsync', { email, password });
  try {
    const apiVersion = (await anonApi.get('version'))?.data;
    if (!apiVersion?.includes('cloud')) {
      console.info('Using basic auth - Box or non cloud detected');
      const token = generateBasicToken(email, password);
      authApi.interceptors.request.clear();
      authGroupApi.interceptors.request.clear();
      adminApi.interceptors.request.clear();
      installBasicAuthHeader(authApi, 'authApi', token);
      installBasicAuthHeader(authGroupApi, 'authGroupApi', token);
      installBasicAuthHeader(adminApi, 'adminApi', token);
      if (localStorage.getItem('token') !== token) {
        localStorage.setItem('token', token);
      }
    } else {
      await firebaseSignIn(email, password);
    }
    return await fetchUserData();
  } catch (e) {
    return thunkAPI.rejectWithValue(
      handleNetworkError('loginAsync', e, args, [
        UNAUTHORIZED_STATUS,
        FORBIDDEN_STATUS,
      ]) as APIErrorClient
    );
  }
});

/**
 * Will now rely on firebase for login
 * Can be a problem when testing a locally hosted backend
 * (LOCAL)
 */
export const restoreLoginAsync = createAsyncThunk<
  ExtendedAuthUserDTOIn,
  void,
  { rejectValue: APIErrorClient }
>('auth/restoreLogin', async (_args, _thunkAPI) => {
  const apiVersion = (await anonApi.get('version'))?.data;
  if (!apiVersion?.includes('cloud')) {
    console.info('Using basic auth - Box or non cloud detected');
    const token = localStorage.getItem('token');
    installBasicAuthHeader(authApi, 'authApi', token);
    installBasicAuthHeader(authGroupApi, 'authGroupApi', token);
    installBasicAuthHeader(adminApi, 'adminApi', token);
  } else {
    await firebaseRestoreSignin();
  }
  return await fetchUserData();
});

export const mfaAsync = createAsyncThunk<
  ExtendedAuthUserDTOIn, // output
  string, // input
  { rejectValue: APIErrorClient } // error output
>('auth/mfa', async (verificationCode: string, thunkAPI) => {
  console.log('mfaAsync', { verificationCode });
  authApi.interceptors.request.clear();
  const state = thunkAPI.getState() as RootState;
  const { session } = state.login.serverError.originalError as MFARequiredError;
  await finishMfaSignIn(verificationCode, session);
  return await fetchUserData();
});

export const logoutAsync = createAsyncThunk<void, void>('auth/logout', async () => {
  await firebaseSignOut();
});

export const signinSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reconfirmEmail: (state) => {
      state.emailConfirmed = false; // FIXME password reset
    },
    resetServerError: (state) => {
      state.serverError = null;
    },
  },
  extraReducers: (bd) => {
    bd.addCase(loginAsync.pending, setLoadingReducer);
    bd.addCase(loginAsync.rejected, (state, action) => {
      if (action.payload) {
        state.loggedIn = false;
        state.loading = false;
        state.serverError = action.payload;
      } else {
        state.loggedIn = false;
        state.loading = false;
        state.serverError = {
          errorKey: UNKNOWN_ERROR_KEY,
          submittedData: {}, // FIXME
        };
      }
    });
    bd.addCase(loginAsync.fulfilled, (state, action) => {
      const userDTO = action.payload;
      if (userDTO) {
        state.loading = false;
        state.loggedIn = true;
        state.serverError = undefined;
        state.userId = userDTO.id;
        for (const key in userDTO) {
          state[key] = userDTO[key];
        }
      }
    });

    bd.addCase(restoreLoginAsync.pending, setLoadingReducer);
    bd.addCase(restoreLoginAsync.rejected, setErrorReducer);
    bd.addCase(restoreLoginAsync.fulfilled, (state, action) => {
      const userDTO = action.payload;
      if (userDTO) {
        state.loading = false;
        state.loggedIn = true;
        state.serverError = undefined;
        state.userId = userDTO.id;
        for (const key in userDTO) {
          state[key] = userDTO[key];
        }
      }
    });

    bd.addCase(logoutAsync.pending, setLoadingReducer);
    bd.addCase(logoutAsync.rejected, setErrorReducer);
    bd.addCase(logoutAsync.fulfilled, (state, _) => {
      state.loggedIn = false;
      state.serverError = undefined;
      state.loading = false;
      localStorage.removeItem('token');
    });

    bd.addCase(mfaAsync.pending, setLoadingReducer);
    bd.addCase(mfaAsync.rejected, setErrorReducer);
    bd.addCase(mfaAsync.fulfilled, (state, action) => {
      const userDTO = action.payload;
      if (userDTO) {
        state.loading = false;
        state.loggedIn = true;
        state.serverError = undefined;
        state.userId = userDTO.id;
        for (const key in userDTO) {
          state[key] = userDTO[key];
        }
      }
    });
  },
});

export const { reconfirmEmail, resetServerError } = signinSlice.actions;
export const selectLoggedIn = (state: RootState) => state.login.loggedIn;