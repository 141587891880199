import { switchLang } from '@/features-slice/app/appSlice';
import { useTranslation, AppLangs } from '@/i18n/useTranslation';
import { useAppDispatch } from '@/stores/useReduxTK';
import React, { ComponentPropsWithRef } from 'react';
import { HRef } from '../HRef/HRef';
const ENV_VARS = import.meta.env;
interface Props extends ComponentPropsWithRef<'footer'> {
  versionName?: string;
}

export const Footer = (p: Props) => {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  return (
    <footer className="mt-5 flex flex-col items-center">
      <small className="text-center" style={{ width: '100%' }}>
        {(p.versionName || ENV_VARS.VITE_PORTAL_VERSION || 'local') + ' - '}
        <HRef href="#" onClick={() => dispatch(switchLang(AppLangs.en))}>
          EN
        </HRef> 
        <HRef href="#"  onClick={() => dispatch(switchLang(AppLangs.fr))}>
          |FR
        </HRef> 
      </small>
      {/* <br/> */}
      <small className="text-center" style={{ marginBottom: 'auto', width: '100%' }}>
        {'© Ionnyk, 2024. All Rights Reserved. '}
      </small>
    </footer>
  );
};
