/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { ComponentPropsWithRef } from 'react';
import { Icons } from '@/components/icons';
import { DecoratedPairing, PairingDTO } from '@/features-slice/pairings/PairingDTO';
import { getDefaultTranslator } from '@/i18n/useTranslation';
import { columnGenerator, sortingGenerator } from '@ionnyk-npm/common-ts';
import { Chip } from '@mui/material';
import { isPairingOffline } from './utils';

export const PStatusColors: Record<
  PairingDTO['pairingStatus'],
  'info' | 'default' | 'primary' | 'secondary' | 'error' | 'success' | 'warning'
> = {
  PENDING: 'warning',
  IN_ERROR: 'error',
  ACTIVE: 'success',
};

// export const FStatusColors: Record<
//   PairingDTO['status'],
//   'info' | 'default' | 'primary' | 'secondary' | 'error' | 'success' | 'warning'
// > = {
//   ONLINE: 'success',
//   OFFLINE: 'warning',
// };

const t = getDefaultTranslator; // FIXME
export const pairingColumns = columnGenerator<DecoratedPairing>([
  'lastSeen',
  // 'connected', FIXME
  // 'status',
  // 'pairingStatus', // hidden, transient
  'batteryLevel',
  'name',
  // @ts-ignore
  'group.name', 
  'frameModel',
  'orientation',
]).map((c) => ({ ...c, Header: c.Header.toLocaleUpperCase() }));

pairingColumns[pairingColumns.findIndex((c) => c.accessor === 'pairingStatus')] = {
  Header: t('network.pairing'),
  accessor: 'pairingStatus',
  // @ts-ignore
  Cell: ({ value }) => <Chip size="small" label={t(value)} color={PStatusColors[value]} />,
};

// FIXME other flag 'status' <=> 'isConnected'
// @ts-ignore
// pairingColumns[pairingColumns.findIndex((c) => c.accessor === 'connected')].Cell = ({ value }) => (
//   <Chip size="small" label={t(value)} color={value ? 'success' : 'warning'} />
// );

pairingColumns[pairingColumns.findIndex((c) => c.accessor === 'name')] = {
  accessor: 'name',
  Header: t('network.name'),
  // @ts-ignore
  Cell: ({ value }) => <span className="font-bold text-md">{value}</span>,
};

interface Props extends ComponentPropsWithRef<'div'> {
  percentValue?: string;
}
const BatteryWrapper = (p: Props) => {
  const { className, percentValue = '', ...rest } = p;
  return (
    <span className="flex flex-row justify-start items-center text-xs">
      {p.children}
      {percentValue ? percentValue : <></>}
    </span>
  );
};

pairingColumns[pairingColumns.findIndex((c) => c.accessor === 'batteryLevel')] = {
  Header: '%',
  accessor: 'batteryLevel',
  // @ts-ignore
  Cell: ({ value, row }) => {
    if (!value) {
      const lastSeenDate = row.original['lastSeen'];
      const offlineHint = lastSeenDate
        ? `⚠️ ${t('network.offline.hint')} ${new Date(lastSeenDate).toLocaleString()} ⚠️`
        : `${t('network.offline.hint')} ???`;
      return (
        <Icons.BatteryUnknown
          titleAccess={offlineHint}
          className="text-gray-500"
          fontSize="medium"
        />
      );
    }
    const percentValue = (Number(value) * 100).toFixed(0) + '%';
    // return <div className={value < 0.25 ? 'text-orange-600' : ''}>{percentValue }</div>;
    if (value < 0.15) {
      return (
        <BatteryWrapper>
          <Icons.BatteryAlert
            titleAccess={percentValue}
            className="text-orange-600"
            fontSize="medium"
          />
        </BatteryWrapper>
      );
    }
    if (value < 0.3) {
      return (
        <BatteryWrapper>
          <Icons.Battery1Bar titleAccess={percentValue} fontSize="medium" />
        </BatteryWrapper>
      );
    }
    if (value < 0.45) {
      return (
        <BatteryWrapper>
          <Icons.Battery2Bar titleAccess={percentValue} fontSize="medium" />
        </BatteryWrapper>
      );
    }
    if (value < 0.6) {
      return (
        <BatteryWrapper>
          <Icons.Battery3Bar titleAccess={percentValue} fontSize="medium" />
        </BatteryWrapper>
      );
    }
    if (value < 0.75) {
      return (
        <BatteryWrapper>
          <Icons.Battery4Bar titleAccess={percentValue} fontSize="medium" />
        </BatteryWrapper>
      );
    }
    if (value < 0.9) {
      return (
        <BatteryWrapper>
          <Icons.Battery5Bar titleAccess={percentValue} fontSize="medium" />
        </BatteryWrapper>
      );
    }
    if (value < 0.95) {
      return (
        <BatteryWrapper>
          <Icons.Battery6Bar titleAccess={percentValue} fontSize="medium" />
        </BatteryWrapper>
      );
    } else {
      return (
        <BatteryWrapper>
          <Icons.BatteryFull titleAccess={percentValue} fontSize="medium" />
        </BatteryWrapper>
      );
    }
  },
};

pairingColumns[pairingColumns.findIndex((c) => c.accessor === 'lastSeen')] = {
  // @ts-ignore
  Header: <Icons.WifiOn fontSize="small" />,
  accessor: 'lastSeen', // FIXME new dto values ...
  // @ts-ignore
  Cell: ({ value, row }) => {
    const isOffline = isPairingOffline(row.original as DecoratedPairing)
    const offlineHint = value
      ? `⚠️ ${t('network.offline.hint')} ${new Date(value).toLocaleString()} ⚠️`
      : `${t('network.offline.hint')} ???`;
    const onlineHint = `${t('network.online.hint')} 24h (${new Date(value).toLocaleString()})`;
    return isOffline ? (
      <Icons.WifiOff titleAccess={offlineHint} fontSize="medium" className="text-orange-600" />
    ) : (
      <Icons.WifiOn titleAccess={onlineHint} fontSize="medium" />
    );
  },
};

pairingColumns[pairingColumns.findIndex((c) => c.accessor === 'frameModel')] = {
  Header: t('network.model'),
  accessor: 'frameModel',
};

// @ts-ignore
pairingColumns[pairingColumns.findIndex((c) => c.accessor === 'group.name')] = {
  Header: t('group'),
  // @ts-ignore
  accessor: 'group.name',
};

pairingColumns[pairingColumns.findIndex((c) => c.accessor === 'orientation')] = {
  Header: t('network.orientation'),
  accessor: 'orientation',
  // @ts-ignore
  Cell: ({ value }) => {
    return (
      <div className="flex flex-row items-center gap-2">
        {value === 'HORIZONTAL' ? (
          <Icons.Landscape titleAccess={t(value as any)} fontSize="medium" />
        ) : (
          <Icons.Portrait titleAccess={t(value as any)} fontSize="medium" />
        )}
      </div>
    );
  },
};

export const initialPairingState = sortingGenerator<PairingDTO>('pairingUuid');
