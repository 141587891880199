import { useTranslation } from '@/i18n/useTranslation';
import { useRouteError } from 'react-router-dom';

export default function Error() {
  const error: any = useRouteError();
  console.error(error);

  const t = useTranslation();

  return (
    <div id="error-page">
      <h1>{t('error.title')}</h1>
      <p>{t('error.subtitle')}</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
