import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  APIReject,
  initialRequestStatus,
  handleNetwError,
  setLoadingReducer,
  setErrorReducer,
} from '@ionnyk-npm/common-ts';
import { authGroupApi } from '@/api-services/api';
import { UserDTO } from './UserDTO';
import { privateAddEntity } from '@/utils/toCommon';

/**
 * ======= ACTIONS (ASYNC) ======
 */

// await authGroupApi.get(`users/${userDTO.uuid}`);

export const fetchUser = createAsyncThunk<
  UserDTO, // output
  string, // input
  APIReject // error output
>('fetchUser', async (uuid: string, thunkAPI) => {
  try {
    const response = (await authGroupApi.get(`/users/${uuid}`))?.data;
    return response as UserDTO;
  } catch (e) {
    return thunkAPI.rejectWithValue(handleNetwError('fetchUser', e, null, [], true));
  }
});
export const fetchUsers = createAsyncThunk<
  UserDTO[], // output
  string, // input
  APIReject // error output
>('fetchUsers', async (groupUuid: string, thunkAPI) => {
  try {
    const response = (await authGroupApi.get(`/groups/${groupUuid}/users`))?.data;
    return response as UserDTO[];
  } catch (e) {
    return thunkAPI.rejectWithValue(handleNetwError('fetchUsers', e, null, [], true));
  }
});

const initialState = {
  total: 0,
  items: {} as Record<string, UserDTO>,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState: { ...initialRequestStatus, ...initialState },
  reducers: {
    addMembers: (state, action) => {},
  },
  extraReducers: (bd) => {
    bd.addCase(fetchUser.pending, setLoadingReducer);
    bd.addCase(fetchUser.rejected, setErrorReducer);
    bd.addCase(fetchUser.fulfilled, (state, action) => {
      const user = action.payload;
      privateAddEntity(state, user);
      // if(!(user.uuid in state.items)) {
      //   state.items[user.uuid] = user;
      //   state.total++;
      // }
      state.loading = false;
    });
    /**/
    bd.addCase(fetchUsers.pending, setLoadingReducer);
    bd.addCase(fetchUsers.rejected, setErrorReducer);
    bd.addCase(fetchUsers.fulfilled, (state, action) => {
      const users = action.payload;
      users.forEach((user) => privateAddEntity(state, user));
      state.loading = false;
    });
  },
});
export const { addMembers } = usersSlice.actions;
