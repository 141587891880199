import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
  User,
  IdTokenResult,
  onIdTokenChanged,
  sendPasswordResetEmail,
  RecaptchaVerifier,
  multiFactor,
  PhoneAuthProvider,
  sendEmailVerification,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  MultiFactorResolver,
  PhoneMultiFactorInfo,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import _ from 'lodash';
import { onBearerTokenRefresh } from './api';
import { MFARequiredError, PasswordError, ReloginRequired, EmailValidationRequired, MissingUserError, MissingPhone2FAError, MissingValidationIdError } from './AuthError';
import { apiUrl, localConfig } from './baseURLs';

/* Facade for https://firebase.google.com/docs/auth/web/start */
const firebaseConfig = {
  apiKey: localConfig.GCP_API_KEY,
  authDomain: localConfig.GCP_AUTH_DOMAIN,
};
console.log('Firebase config loaded for url = ', { apiUrl });

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
const phoneAuthProvider = new PhoneAuthProvider(auth);
auth.tenantId = localConfig.TENANT;

export type FirebaseUser = Pick<User, 'uid' | 'email' | 'emailVerified'> & {
  idToken: string;
  jwtToken: string;
  expirationTime: string;
};

// Global listener for token refresh or sign in/sign out event
const REFRESH_RATE = 1000 * 60 * 60 - 1000 * 60; // 1 hour - 1 min
onIdTokenChanged(auth, async (newUser: User | null) => {
  if (newUser) {
    console.log('onIdTokenChanged - signed In/refreshed', newUser.uid);
    const newFirebaseUser: FirebaseUser = await user2FirebaseUser(newUser);
    onBearerTokenRefresh(newFirebaseUser.idToken);
    setTimeout(async() => await newUser.getIdToken(true), REFRESH_RATE);
  } else {
    console.log('onIdTokenChanged - signed Out');
  }
});

const user2FirebaseUser = async (user: User): Promise<FirebaseUser> => {
  const idToken = await user.getIdToken(); // args = forceRefresh: boolean
  const idTokenResult: IdTokenResult = await user.getIdTokenResult();
  const jwtToken = idTokenResult.token;
  const expirationTime = idTokenResult.expirationTime;
  const firebaseUser: FirebaseUser = {
    ..._.pick(user, ['uid', 'email', 'emailVerified']),
    idToken,
    jwtToken,
    expirationTime,
  };
  return firebaseUser;
};

export const firebaseSignOut = async () => {
  const { currentUser } = auth;
  if (currentUser) {
    console.log('firebaseSignOut: currentUser', currentUser.uid);
    await signOut(auth);
  } 
};

export const firebaseSignIn = async (
  email: string,
  password: string
) => {
  console.log('firebaseSignIn: email/password from login');
  await setPersistence(auth, browserLocalPersistence);
  try {
    const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
    const user: User  = userCredential.user;
    const firebaseUser: FirebaseUser = await user2FirebaseUser(user);
    console.log('signInWithEmailAndPassword success', firebaseUser.uid);
    return firebaseUser;
  } catch (e) {
    /**
     * Other:
     * - 'auth/invalid-email'
     * - 'auth/email-already-in-use'
     */
    if (e?.code === 'auth/multi-factor-auth-required') {
      console.log('The user is a multi-factor user. Second factor challenge is required.');
      const session = await startMfaSignIn(e);
      throw new MFARequiredError(session);
    } else if (e?.code === 'auth/wrong-password') {
      throw new PasswordError('Wrong password');
    } else {
      console.warn('signInWithEmailAndPassword', e);
      throw e;
    }
  }
};

export const firebaseRestoreSignin = async () => {
  await auth.authStateReady();
  const { currentUser } = auth;
  if (currentUser) {
    console.info('firebaseSignIn: currentUser restored', currentUser.uid);
    const firebaseUser: FirebaseUser = await user2FirebaseUser(currentUser);
    return firebaseUser;
  } else {
    throw new ReloginRequired();
  }
};

// FIXME Backend will not change the stored psw in db
// https://support.google.com/firebase/answer/7000714
export const firebaseForgotPassword = async (email: string) =>
  await sendPasswordResetEmail(auth, email);

// MFA
const handleRecaptcha = async (containerId: string) =>
  new RecaptchaVerifier(auth, containerId, {
    size: 'invisible',
    callback: function (response: any) {
      console.log('recaptchaVerifier success callback', { response });
    },
    'expired-callback': function () {
      console.warn('recaptchaVerifier expired callback');
    },
  }) as RecaptchaVerifier;

const handleEmailVerification = async () => {
  /*
    Firebase: Need to verify email first before enrolling second factors. (auth/unverified-email).
  */
  const currentUser = auth.currentUser;
  if (!currentUser) {
    throw new MissingUserError();
  }
  if (!currentUser.emailVerified) {
    console.warn('firebaseMultiFactor: email not verified');
    await sendEmailVerification(currentUser);
    throw new EmailValidationRequired();
  } else {
    console.log('firebaseMultiFactor: email verified');
  }
  return currentUser;
};

export const getMfaEnrollement = () => {
  const { currentUser } = auth;
  const mfaUser = multiFactor(currentUser);
  const mfaInfos = mfaUser.enrolledFactors.filter((mfi) => mfi.factorId === 'phone');
  return mfaInfos?.[0] as PhoneMultiFactorInfo | undefined;
};

export const startEnrollingMFA = async (phoneNumber: string) => {
  console.log('startEnrollingMFA');
  const recaptchaVerifier = await handleRecaptcha('mfa-enroll-btn');
  const currentUser = await handleEmailVerification();
  const mfaUser = multiFactor(currentUser);
  const session = await mfaUser.getSession();

  const phoneInfoOptions = {
    phoneNumber,
    session,
  };

  let validationId: string;
  try {
    validationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    console.log('firebaseMultiFactor: verifyPhoneNumber', { validationId });
    return validationId;
  } catch (err) {
    if (err.code === 'auth/requires-recent-login') {
      throw new ReloginRequired();
    }
    console.warn('verifyPhoneNumber, retrying with cleared recaptchaVerifier: ', err);
    // From : https://firebase.google.com/docs/auth/web/multi-factor
    // If the request fails, reset the reCAPTCHA, then repeat the previous step so the user can try again. Note that verifyPhoneNumber() will automatically reset the reCAPTCHA when it throws an error, as reCAPTCHA tokens are one-time use only.
    recaptchaVerifier.clear();
    validationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    console.log('firebaseMultiFactor: verifyPhoneNumber', { validationId });
    return validationId;
  }
};

export const finishEnrollingMFA = async (
  session: Partial<MFASession>,
  verificationCode: string
) => {
  console.log('finishEnrollingMFA');
  const { validationId } = session;
  const cred = PhoneAuthProvider.credential(validationId, verificationCode);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  if (!auth.currentUser) {
    throw new MissingUserError();
  }
  const mfaUser = multiFactor(auth?.currentUser);
  mfaUser.enroll(multiFactorAssertion, 'My personal phone number');
};

export type MFASession = {
  resolver: MultiFactorResolver;
  validationId: string;
  phoneFactor: PhoneMultiFactorInfo;
  stage: 'PHONE' | 'CODE' | null;
};

const startMfaSignIn = async (e: any) => {
  const resolver = getMultiFactorResolver(auth, e);
  const mfaInfos = resolver.hints.filter(
    (hint) => hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID
  );
  if (mfaInfos.length === 0) {
    throw new MissingPhone2FAError();
  }
  const phoneFactor = mfaInfos[0] as PhoneMultiFactorInfo;
  console.log('firebaseSignIn: phoneFactor', { phoneFactor });
  const recaptchaVerifier = await handleRecaptcha('login-b2b-btn');
  const phoneInfoOptions = {
    multiFactorHint: phoneFactor,
    session: resolver.session,
  };
  let validationId: string;
  try {
    validationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
  } catch (e) {
    recaptchaVerifier.clear();
    validationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
  }
  if (!validationId) {
    throw new MissingValidationIdError();
  }
  console.log('firebaseSignIn: verifyPhoneNumber', { validationId });
  return {
    resolver,
    validationId,
    phoneFactor,
    stage: 'CODE',
  } as MFASession;
};

export const finishMfaSignIn = async (verificationCode: string = '', session: MFASession) => {
  const { resolver, validationId } = session;
  const cred = PhoneAuthProvider.credential(validationId, verificationCode);
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
  const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
  const user = userCredential.user;
  const firebaseUser: FirebaseUser = await user2FirebaseUser(user);
  console.log('firebaseSignIn: finishMfaSignIn success', firebaseUser.uid);
  return firebaseUser;
};