import {
  finishEnrollingMFA,
  getMfaEnrollement,
  MFASession,
  startEnrollingMFA,
} from '@/api-services/firebase';
import { MCard } from '@/components/Card/MCard';
import { H1 } from '@/components/H1';
import { KVTable } from '@/components/KVTable';
import { logoutAsync } from '@/features-slice/auth/slice/signinSlice';
import { useTranslation } from '@/i18n/useTranslation';
import { useAppDispatch, useAppSelector } from '@/stores/useReduxTK';
import { formatPeopleFullName, UNKNOWN_ERROR_KEY } from '@ionnyk-npm/common-ts';
import { Button } from '@mui/material';
import { ComponentPropsWithRef, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { MFAModal } from '../SignIn/MFAModal';
import { TKeys } from '@/i18n/en';
import { ReloginRequired, MissingUserError, AuthError } from '@/api-services/AuthError';

interface Props extends ComponentPropsWithRef<'div'> {}
export const Profile = (p: Props) => {
  const { className, ...rest } = p;
  const login = useAppSelector((state) => state.login);
  const authState = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();
  const t = useTranslation();

  const [mfaSession, setMfaSession] = useState<Partial<MFASession>>(null);

  const [mfaPhone, setMfaPhone] = useState<string>('');

  useEffect(() => {
    // After 2FA enrollment, refreshing the phone number is not working
    // requires a page refresh
    console.info('Refreshing phone', getMfaEnrollement()?.phoneNumber);
    setMfaPhone(getMfaEnrollement()?.phoneNumber);
  }, [mfaSession]);

  return (
    <div className={twMerge('p-5', className)} {...rest}>
      <MFAModal
        session={mfaSession}
        onClose={() => setMfaSession(null)}
        onSubmit={async (data: string) => {
          try {
            if (mfaSession.stage === 'PHONE') {
              console.log('PHONE submit');
              const validationId = await startEnrollingMFA(data);
              console.log({ validationId });
              setMfaSession((s) => ({
                ...s,
                validationId,
                stage: 'CODE',
              }));
            } else if (mfaSession.stage === 'CODE') {
              console.log('Code submit');
              await finishEnrollingMFA(mfaSession, data);
              alert(t('profile.add_2fa.success'));
              setMfaPhone(data);
              setMfaSession(null);
            }
          } catch (e) {
            if (e instanceof ReloginRequired) {
              alert(t(e.errorKey as TKeys));
              dispatch(logoutAsync());
            } else if (e instanceof AuthError) {
              alert(t(e.errorKey as TKeys));
            } else {
              console.warn('onSubmit 2FA', e);
              alert(UNKNOWN_ERROR_KEY);
            }
          }
        }}
      />
      <H1 className="mb-3">{t('profile.title')}</H1>
      <MCard
        title={formatPeopleFullName(login)}
        subtitle={t('profile.subtitle')}
        buttonTxt={t('btn_logout')}
        buttonOnClick={() => {
          dispatch(logoutAsync());
        }}
      >
        <KVTable
          map={{
            Email: login.email,
            Group: login.group?.name,
            Manager: t(login?.roles?.includes('GROUP_MANAGER') ? 'yes' : 'no'),
          }}
        />

        <br />
        <Button
          variant="outlined"
          className="w-full"
          type="submit"
          id="mfa-enroll-btn"
          disabled={authState.loading || !!mfaPhone}
          onClick={async (e) => {
            e.preventDefault(); // stop submit form
            e.stopPropagation();
            setMfaSession({
              stage: 'PHONE',
            });
          }}
        >
          {mfaPhone ? `${t('profile.read_2fa')} ${mfaPhone}` : t('profile.add_2fa')}
        </Button>
      </MCard>
    </div>
  );
};
