import { routesMap } from '@/pages/routes';
import { ComponentPropsWithRef } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import logoI from '@/assets/ionnyk_logo_white.png';
import _ from 'lodash';
import { Button } from '@mui/material';
import { useTranslation } from '@/i18n/useTranslation';
// FIXME duplicated code?

const TopNavigation = ({ children }: any) => {
  const navigation = useNavigate();
  const location = useLocation();
  const active = location.pathname === routesMap.root.path;
  return (
    <div className="flex flex-row items-center w-full h-20 m-0 px-4 shadow-lg justify-between bg-opacity-90 bg-primary">
      <img src={logoI} className="h-4" />
      {children}
      <div className="flex flex-row items-center">
        <Title />
        <UserCircle
          active={active}
          onClick={() => navigation(active ? -1 as any : routesMap.root.path)}
        />
      </div>
    </div>
  );
};

const UserCircle = (p: ComponentPropsWithRef<typeof Button> & { active: boolean }) => (
  <Button {..._.omit(p,'active')}>
    <TitleHidden />
    <FaUserCircle
      size="24"
      className={`${
        p.active ? 'text-neutral-100' : 'text-neutral-500'
      } transition duration-300 ease-in-out cursor-pointer  ${
        p.active ? 'hover:text-neutral-0' : 'hover:text-neutral-400'
      }`}
    />
  </Button>
);
const Title = () => {
  const t = useTranslation();
  return (
    <h5 className="my-auto ml-2 text-xl font-semibold tracking-wider text-white transition duration-300 ease-in-out text-opacity-80">
      {t('network.manager')}
    </h5>
  );
}

const TitleHidden = () => {
  const t = useTranslation();
  return (
  <h5 className="hidden my-auto ml-2 text-xl font-semibold tracking-wider text-neutral-500 transition duration-300 ease-in-out text-opacity-80">
     {t('network.manager')}
  </h5>
)};


export default TopNavigation;
