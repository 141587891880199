import axios, { AxiosInstance } from 'axios';
import { axiosBaseConfig, UNAUTHORIZED_STATUS } from '@ionnyk-npm/common-ts';
import { store } from '@/stores/store';
import { logoutAsync } from '@/features-slice/auth/slice/signinSlice';
import { BASE_URL_ANON, BASE_URL, BASE_URL_GROUP, BASE_URL_ADMIN } from './baseURLs';

export const anonApi: AxiosInstance = axios.create({
  ...axiosBaseConfig,
  baseURL: BASE_URL_ANON,
});

export const authApi: AxiosInstance = axios.create({
  ...axiosBaseConfig,
  baseURL: BASE_URL,
});

export const authGroupApi: AxiosInstance = axios.create({
  ...axiosBaseConfig,
  timeout: 20000, // FIXME optim API
  baseURL: BASE_URL_GROUP,
});

export const adminApi: AxiosInstance = axios.create({
  ...axiosBaseConfig,
  baseURL: BASE_URL_ADMIN,
});

// FIXME update common-ts
const interceptorsMap: Record<string, number | null> = {};
export function installBearerAuthHeader(
  axiosInstance: AxiosInstance,
  apiName: string,
  token: string
) {
  axiosInstance.interceptors.request.clear();
  axiosInstance.interceptors.request.eject(interceptorsMap[apiName]);
  interceptorsMap[apiName] = axiosInstance.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.clear();
  axiosInstance.interceptors.response.eject(
    interceptorsMap[`response-${interceptorsMap[apiName]}`]
  );
  interceptorsMap[`response-${interceptorsMap[apiName]}`] = axiosInstance.interceptors.response.use(
    (response) => {
      if (
        response.status === UNAUTHORIZED_STATUS &&
        window.confirm('Session expired. Please login again')
      ) {
        store.dispatch(logoutAsync()); // TODO cyclic import, signinSlice.ts requires this api.ts to be loaded
      }
      return response;
    },
    (error) => Promise.reject(error)
  );
}

export const onBearerTokenRefresh = (bearerToken: string) => {
  installBearerAuthHeader(authApi, 'authApi', bearerToken);
  installBearerAuthHeader(authGroupApi, 'authGroupApi', bearerToken);
  installBearerAuthHeader(adminApi, 'adminApi', bearerToken);
};
console.info('api.ts loaded');
