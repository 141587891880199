import { useTranslation } from '@/i18n/useTranslation';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import React, { ComponentPropsWithRef } from 'react';

interface Props<T extends string> extends ComponentPropsWithRef<typeof ToggleButtonGroup> {
  keys: T[];
  mapping?: Record<string, string>;
}

export const AtwFilters = <T extends string>(p: Props<T>) => {
  const { className, value, mapping = {} as Map<string, string>, onChange, keys, ...rest } = p;
  const t = useTranslation();
  return (
    <ToggleButtonGroup
      className={className}
      size="small"
      color="primary"
      value={value}
      exclusive
      onChange={onChange}
    >
      {value}
      {keys.map((key: string) => (
        <ToggleButton key={key} value={key}>
          {key in mapping ? mapping[key] : t(key as any)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
