import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import React, { ComponentPropsWithRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props extends ComponentPropsWithRef<'div'> {
    titleClassName?: any;
    bodyClassName?: any;
    title: string;
}
export const AccordionWrapper = (p: Props) => {
  const { className, title, children, bodyClassName ='', titleClassName='', ...rest } = p;
  return (
    <Accordion {...rest} className={className}>
      <AccordionSummary className={titleClassName} expandIcon={<ArrowDropDownIcon />}>
        <Typography variant="body1">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={bodyClassName}>{children}</AccordionDetails>
    </Accordion>
  );
};
