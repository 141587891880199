import React, { ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends ComponentPropsWithRef<'div'> {}
export const WindowContainer = (p: Props) => {
  const { className, ...rest } = p;
  return (
    <div
      className={twMerge(
        'flex flex-1 flex-col h-screen w-full m-0 overflow-hidden bg-light',
        className
      )}
      {...rest}
    >
      {p.children}
    </div>
  );
};
