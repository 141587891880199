import React, { ComponentPropsWithRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends ComponentPropsWithRef<'form'> {}
const FormComp = (p: Props) => {
  const { className, ...rest } = p;
  return (
    <form className={twMerge('flex flex-col my-5 space-y-3', className)} {...rest}>
      {p.children}
    </form>
  );
};
export const Form = FormComp;
// FormComp.Group = () => <div>Group</div>; FIXME
