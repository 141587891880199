import { ArtworkDTO } from '@/features-slice/artworks/ArtworkDTO';
import { ComponentPropsWithRef } from 'react';
import { FlexContainer } from '@/components/FlexContainer';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));


interface Props extends ComponentPropsWithRef<'div'> {
  artworks: ArtworkDTO[];
}

const classFromCombi = {
  'JANE-VERTICAL': {
    width: 3,
    heght: 4,
  },
  'JANE-HORIZONTAL': {
    width: 4,
    heght: 3,
  },
  'LINN-VERTICAL': {
    height: 16,
    width: 9,
  },
  'LINN-HORIZONTAL': {
    height: 9,
    width: 16,
  },
};

export const BatchPreview = (p: Props) => {
  const { className, artworks = [], ...rest } = p;
  const images = artworks.map((a) => {
    const preview = a.previews?.find((p) => p.label === 'SM');
    const combi = `${a.compatibleFrameModels[0]}-${a.orientation}`;
    const className = classFromCombi[combi];
    return {
      ...preview,
      // ...className,
      // label: undefined,
      title: a.title,
      img: preview?.url,
    };
  });

  // console.log({ images });
  return (
    <FlexContainer
      subContainer
      loading={images.length === 0}
      className="flex-1 w-full h-screen overflow-y-auto"
      {...rest}
    >
      {!!images.length && <Masonry columns={5} spacing={2}>
        {images.map((item, index) => (
          <div key={index}>
            <Label>{item.title}</Label>
            <img
              // srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
              src={`${item.img}`}// &w=162&auto=format
              alt={item.title}
              loading="eager"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: 'block',
                width: '100%',
              }}
            />
          </div>
        ))}
      </Masonry>}
    </FlexContainer>
  );
  // return (
  //   //     // GridContainer className={'grid-flow-col auto-cols-max gap-4 '}
  //   <FlexContainer className='w-full flex-wrap gap-2 debug' {...rest}>
  //     {artworks.map((a) => {
  //       // const combi = `${a.compatibleFrameModels[0]}-${a.orientation}`;
  //       // const className = classFromCombi[combi];
  //       // console.log({ combi, className })
  //       return (
  //         <AtwCard
  //           key={a.uuid}
  //           artwork={a}
  //           // className={className}
  //         />
  //       )
  //     })}
  //   </FlexContainer>
  // );
};
