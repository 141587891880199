import { BatchResponse } from '@/features-slice/batch/batchDTO';
import { dateStrToLocaleDate } from '@ionnyk-npm/common-ts';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import { ComponentPropsWithRef, useEffect, useState } from 'react';
import { BsPlayCircle as PlayIcon } from 'react-icons/bs';
import { DispatchModal } from './DispatchModal';
import { browserAppLang, useTranslation } from '@/i18n/useTranslation';
import { Alert } from '@mui/material';
import { useAppSelector } from '@/stores/useReduxTK';

interface Props extends ComponentPropsWithRef<'div'> {
  batches: BatchResponse[];
  selectedBatchUuid: string;
  onSelectedBatch: (uuid: string) => void;
  onPlayBatch: (uuid: string, groupUuid: string, startDate: Date) => void;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // @ts-ignore
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const BatchList = (p: Props) => {
  const { className, batches, selectedBatchUuid, onSelectedBatch, onPlayBatch, ...rest } = p;

  const [batchUuid, setBatchUuid] = useState<string | null>(null);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { group: rootGroup } = useAppSelector((state) => state.login);

  // .sort((b1, b2) => b1?.name?.en?.localeCompare(b2?.name?.en))
  const sortedBatches = batches.sort((a, b) => {
    const v =
      new Date(b.creationMoment).getTime() -
      new Date(a.creationMoment).getTime();
    return v;
  });

  const t = useTranslation();

  useEffect(() => {
    if (success || error) {
      setTimeout(() => {
        setSuccess('');
        setError('');
      }, 2000);
    }
  }, [success, error]);

  return (
    <div className={className} {...rest}>
      <DispatchModal
        batchUuid={batchUuid}
        onClose={() => setBatchUuid(null)}
        onConfirm={(startDate: Date) => {
          onPlayBatch(batchUuid, rootGroup.uuid, startDate);
          setBatchUuid(null);
          setSuccess(t('batch.success'));
        }}
      />
      {success && (
        <Alert
          className="absolute top-4 left-4 w-2/12"
          severity="success"
          onClose={() => setSuccess('')}
        >
          {t(success as any)}
        </Alert>
      )}
      {error && (
        <Alert severity="success" onClose={() => setError('')}>
          {t(error as any)}
        </Alert>
      )}
      {/* <CustomizedDialogs /> */}
      <div className="overflow-x-auto">
        <Table>
          {/* head */}
          <TableHead className="bg-secondary">
            <TableRow>
              <TableCell className="font-bold">{t('batch.name')}</TableCell>
              <TableCell className="font-bold">{t('batch.creationdate')}</TableCell>
              <TableCell className="font-bold">{t('batch.total')}</TableCell>
              <TableCell className="font-bold"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedBatches
              .map((b) => (
                <StyledTableRow
                  hover
                  key={b.uuid}
                  onClick={() => onSelectedBatch(b.uuid)}
                  className={`p-2 ${b.uuid === selectedBatchUuid ? 'bg-neutral-400' : ''}`}
                >
                  <TableCell>{b?.name?.[browserAppLang] ?? b?.name?.en}</TableCell>
                  <TableCell>{dateStrToLocaleDate(b.creationMoment)}</TableCell>
                  <TableCell>{b.numberOfArtworks}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="START"
                      onClick={(e) => {
                        e.stopPropagation();
                        setBatchUuid(b.uuid);
                        return false;
                      }}
                    >
                      <PlayIcon />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
