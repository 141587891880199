import { MainFlexContainer } from '@/components/MainFlexContainer';
import { useTranslation } from '@/i18n/useTranslation';
import React from 'react';

// Blank page during public/private resolution
// FIXME appears also for login page ...
export const PublicLoading = () => {
  const t = useTranslation();
  return (
    <MainFlexContainer>
      <h2>{t('loading')}</h2>
    </MainFlexContainer>
  );
};
