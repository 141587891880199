import { TextField } from '@mui/material';
import React, { ComponentPropsWithRef } from 'react';

interface Props extends ComponentPropsWithRef<typeof TextField> {
  // children: React.ReactNode;
}
export const Input = (p: Props) => {
  //  className="input" <input>
  return <TextField size='medium' {...p} className='bg-white' />;
};
