import { RootState } from '@/stores/store';
import { useAppDispatch, useAppSelector } from '@/stores/useReduxTK';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import { ItemsResponse, RequestStatus } from '@ionnyk-npm/common-ts';

type LoaderProps<E, I, C> = {
  rootEntitiesSelector: (state: RootState) => {
    items: Record<string, E>;
    total: number;
  } & RequestStatus;
  arg?: I | void;
  loaderThunk: AsyncThunk<ItemsResponse<E>, I, any>;
};
type LoaderOutput<E> = {
  loading: boolean;
  items: Record<string, E>;
  entities: E[];
};

export function useItemsLoader<E, I, C>(p: LoaderProps<E, I, C>): LoaderOutput<E> {
  const { rootEntitiesSelector, arg, loaderThunk } = p;
  const dispatch = useAppDispatch();
  const rootEntities = useAppSelector(rootEntitiesSelector);
  const { loading, items, total, initialLoading } = rootEntities;
  const entities = useMemo(() => Object.values(items), [items]);

  useEffect(() => {
    {
      if (!loading && initialLoading === 'BLANK') {
        // @ts-ignore
        dispatch(loaderThunk(arg));
      }
    }
  }, [dispatch, arg, loaderThunk, loading, initialLoading]);

  return { loading, items, entities };
}
