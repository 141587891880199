import React, {
  ComponentProps,
  ElementRef,
  ForwardedRef,
  Fragment,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import { Tab } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import { inMenuRoutes } from '../../../pages/routes';
import _ from 'lodash';
import { WindowContainer } from '@/components/WindowContainer';
import { useAppDispatch, useAppSelector } from '@/stores/useReduxTK';
import { restoreLoginAsync } from '@/features-slice/auth/slice/signinSlice';
import TopNavigation from './TopNav';
import { unwrapResult } from '@reduxjs/toolkit';

const placementForIndex = (index: number): 'center' | 'left' | 'right' => {
  switch (index) {
    case 0:
      return 'left';
    case inMenuRoutes.length - 1:
      return 'right';
  }
  return 'center';
};

interface Props {
  children?: React.ReactNode;
}

interface TabButtonProps extends ComponentProps<typeof Link> {
  selected: boolean;
  placement: 'left' | 'center' | 'right';
}
const TabButton = forwardRef(
  (props: TabButtonProps, ref: ForwardedRef<ElementRef<typeof Link>>) => {
    return (
      <Link
        // to={props.to} // already spread
        // ref={ref} // FIXME
        {...props}
        className={`p-3 mt-12 bg-red-500 ${
          props.selected ? 'bg-secondary text-white' : 'bg-white text-black'
        } ${props.placement === 'left' ? 'rounded-l-3xl' : ''} ${
          props.placement === 'right' ? 'rounded-r-3xl' : ''
        }`}
      >
        {props.children}
      </Link>
    );
  }
);
TabButton.displayName = 'TabButton'; // https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/display-name.md

export const App = (p: Props) => {
  const authState = useAppSelector((state) => state.login);
  const loggedIn = authState.loggedIn;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    const selectedIndex = inMenuRoutes.findIndex((r) => r.path === location.pathname);
    setSelectedIndex(selectedIndex);
  }, [location.pathname]);

  useEffect(() => {
    if (!authState.loggedIn) {
      dispatch(restoreLoginAsync())
        .then((r) => {
          const rr = unwrapResult(r);
          console.log('loginAsync reconnected.', rr);
        })
        .catch((e) => {
          console.warn('loginAsync reconnected failed. classical login, silent', e);
        });
    }
  }, [dispatch, authState.loggedIn]);

  if (!loggedIn) {
    // public route !
    return <WindowContainer>{p.children}</WindowContainer>;
  }

  return (
    <WindowContainer>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <TopNavigation>
          <Tab.List className="my-4 shadow-white">
            {inMenuRoutes.map((r, index) => (
              <Tab key={r.key} as={Fragment}>
                {({ selected }) => (
                  <TabButton
                    style={{
                      textDecoration: 'none',
                    }}
                    to={r.path}
                    selected={selected && selectedIndex !== -1}
                    placement={`${placementForIndex(index)}`}
                  >
                    {r.label}
                  </TabButton>
                )}
              </Tab>
            ))}
          </Tab.List>
        </TopNavigation>

        <div className="flex flex-1 flex-col w-full items-center h-full px-0 mx-auto mt-0 ml-0 overflow-y-scroll">
          {/* FIXME issues with the height of this and the overflow */}
          <Tab.Panels as={Fragment}>
            {inMenuRoutes.map((r) => (
              <Tab.Panel as={Fragment} key={r.key}>
                {p.children}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </WindowContainer>
  );
};
