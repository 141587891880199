import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  APIReject,
  initialRequestStatus,
  handleNetwError,
  setLoadingReducer,
  setErrorReducer,
} from '@ionnyk-npm/common-ts';
import { authGroupApi } from '@/api-services/api';
import { ScheduledDispatchResponse, ScheduledDispatchRequest } from './scheduledDispatchDTO';
import { makeThunk, privateAddEntity } from '@/utils/toCommon';


/**
 * ======= ACTIONS (ASYNC) ======
 */
const basePath = '/dispatches';
export const fetchScheduledDispatches = createAsyncThunk<
  ScheduledDispatchResponse[], // output
  void, // input
  APIReject // error output
>('fetchScheduledDispatches', async (_, thunkAPI) => {
  try {
    await new Promise((r) => setTimeout(r, 1000));
    // return mock as ScheduledDispatchResponse[];
    return (await authGroupApi.get(`${basePath}/`))?.data as ScheduledDispatchResponse[];
  } catch (e) {
    return thunkAPI.rejectWithValue(handleNetwError('fetchScheduledDispatches', e, null, [], true));
  }
});

/**
  404 : UNKNOWN_BATCH, UNKNOWN_GROUP
  400: INVALID_DISPATCH_MOMENT
 */
export const postScheduledDispatches = createAsyncThunk<
  ScheduledDispatchResponse, // output
  ScheduledDispatchRequest, // input
  APIReject // error output
>('postScheduledDispatches', async (request: ScheduledDispatchRequest, thunkAPI) => {
  try {
    return (await authGroupApi.post(`${basePath}`, request))?.data as ScheduledDispatchResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(handleNetwError('fetchScheduledDispatches', e, null, [], true));
  }
});

export type ChangeStatusRequest = {
  dispatchUuid: string;
  status: ScheduledDispatchResponse['status'];
}
export const changeStatus = makeThunk<ScheduledDispatchResponse, ChangeStatusRequest>('changeStatus', async (req: ChangeStatusRequest) =>
  {
    return await authGroupApi.put(`${basePath}/${req.dispatchUuid}/status`, req)
  }
);

const initialState = {
  total: 0,
  items: {} as Record<string, ScheduledDispatchResponse>,
};
export const dispatchesSlice = createSlice({
  name: 'dispatches',
  initialState: { ...initialRequestStatus, ...initialState },
  reducers: {},
  extraReducers: (bd) => {
    // dispatches
    bd.addCase(fetchScheduledDispatches.pending, setLoadingReducer);
    bd.addCase(fetchScheduledDispatches.rejected, (state, action) => {
      setErrorReducer(state, action);
      state.initialLoading = state.initialLoading === 'BLANK' ? 'ERROR' : state.initialLoading;
    });
    bd.addCase(fetchScheduledDispatches.fulfilled, (state, action) => {
      const dispatches = action.payload;
      dispatches.forEach((dispatches) => {
        privateAddEntity(state, {
          ...dispatches,
          artworksUUIDs: [],
        });
      });
      state.loading = false;
      state.initialLoading = state.initialLoading === 'BLANK' ? 'INITIAL' : state.initialLoading;
    });

    //
    bd.addCase(postScheduledDispatches.pending, setLoadingReducer);
    bd.addCase(postScheduledDispatches.rejected, setErrorReducer);
    bd.addCase(postScheduledDispatches.fulfilled, (state, action) => {
      const dispatch = action.payload;
      const uuid = dispatch.uuid;
      const oldDispatch = state.items[uuid];
      if (oldDispatch) {
        state.items[uuid] = {
          ...oldDispatch,
          ...dispatch,
        };
      } else {
        privateAddEntity(state, dispatch);
      }
      state.loading = false;
    });
  },
});
// export const { } = dispatchesSlice.actions;
