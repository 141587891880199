import { DecoratedGroup, fetchChildren, fetchGroup } from '@/features-slice/group/GroupSlice';
import { RootState } from '@/stores/store';
import { useAppDispatch, useAppSelector } from '@/stores/useReduxTK';
import { useCallback, useEffect, useMemo } from 'react';
import { fetchPairings } from '@/features-slice/pairings/PairingSlice';
import { fetchUsers } from '@/features-slice/users/UserSlice';
import { useInterval } from './useInterval';

type Output = {
  loaded: boolean;
  group: DecoratedGroup;
};

export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.floor(Math.random() * 16);
    if (c === 'x') {
      return r.toString(16);
    } else {
      const v = (r % 4) + 8;
      return v.toString(16);
    }
  });
}

const usePolledDataFetcher = (dispatch: any, fetcher: any, uuid: string, interval: number) => {
  const fetcherInstance = useCallback(() => dispatch(fetcher(uuid)), [dispatch, fetcher, uuid]);
  useInterval(fetcherInstance, interval);
};

const useDataFetcher = (dispatch: any, fetcher: any, uuid: string, entity) => {
  // FIXME 'initialLoading?'
  const fetched = useMemo(() => uuid.length && entity !== undefined, [entity, uuid]);
  useEffect(() => {
    if (!fetched && uuid.length) {
      console.info('need2fetch', { uuid });
      dispatch(fetcher(uuid));
    } else {
      console.info('no need2fetch', { fetched, uuid });
    }
  }, [dispatch, fetched, fetcher, uuid]); //fetcher

  return fetched;
};

export const useDecoratedGroupPoller = (groupUuid: string, interval: number) => {
  const dispatch = useAppDispatch();
  const decoratedGroup = useAppSelector((s: RootState) => s.group.items?.[groupUuid]);

  const loadingGroups = useAppSelector((s: RootState) => s.group.loading);
  const loadingPairings = useAppSelector((s: RootState) => s.pairings.loading);
  const loadingUsers = useAppSelector((s: RootState) => s.users.loading);

  usePolledDataFetcher(dispatch, fetchGroup, groupUuid, interval);
  usePolledDataFetcher(dispatch, fetchPairings, groupUuid, interval);
  usePolledDataFetcher(dispatch, fetchChildren, groupUuid, interval);
  usePolledDataFetcher(dispatch, fetchUsers, groupUuid, interval);

  return {
    loaded: !loadingGroups && !loadingPairings && !loadingUsers, // FIXME no 'loaded' per se
    decoratedGroup,
  };
};

export const useDecoratedGroup = (groupUuid: string) => {
  const dispatch = useAppDispatch();
  const group = useAppSelector((s: RootState) => s.group.items?.[groupUuid]);

  const groupFetched = useDataFetcher(dispatch, fetchGroup, groupUuid, group);


  const pairingFetched = useDataFetcher(dispatch, fetchPairings, groupUuid, group?.pairings);


  const childrenFetched = useDataFetcher(dispatch, fetchChildren, groupUuid, group?.children);


  const usersFetched = useDataFetcher(dispatch, fetchUsers, groupUuid, group?.users);


  return {
    loaded: groupFetched && pairingFetched && childrenFetched && usersFetched,
    decoratedGroup: group,
  };
};
