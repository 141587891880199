import React, { ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends ComponentPropsWithRef<'h1'> {}
export const H1 = (p: Props) => {
  const { className, ...rest } = p;
  return (
    <h1 className={twMerge('text-3xl', className)} {...rest}>
      {p.children}
    </h1>
  );
};
