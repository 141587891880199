import { RootState } from '@/stores/store';
import { useAppDispatch, useAppSelector } from '@/stores/useReduxTK';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useEffect, useMemo, useState } from 'react';
import { RequestStatus } from '@ionnyk-npm/common-ts';
import { AxiosError } from 'axios';

type LoaderProps<E, C> = {
  entityUuid: string;
  rootEntitySelector: (state: RootState) => {
    items: Record<string, E>;
    total: number;
  } & RequestStatus;
  loaderThunk: AsyncThunk<E, string, any>;
};
type LoaderOutput<E> = {
  items: Record<string, E>;
  entity: E;
} & RequestStatus;

export function useEntityLoader<E, C>(p: LoaderProps<E, C>): LoaderOutput<E> {
  const { rootEntitySelector, entityUuid, loaderThunk } = p;
  const dispatch = useAppDispatch();
  const rootEntity = useAppSelector(rootEntitySelector);
  const { loading, items, initialLoading, serverError } = rootEntity;
  const entity = useMemo(() => items[entityUuid], [items, entityUuid]);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    {
      if (!loading && !notFound && !serverError && !entity) {
        console.info('fetching entity', entityUuid);
        dispatch(loaderThunk(entityUuid)).catch((e) => {
          // FIXME 404 stop infinite loop
          if (e instanceof AxiosError && e.response?.status === 404) {
            setNotFound(true);
          }
        });
      } else {
        console.info('fetching entity skip', entityUuid);
      }
    }
  }, [dispatch, loaderThunk, loading, entityUuid, entity, notFound, serverError]);

  return { loading, items, entity, initialLoading, serverError };
}
