import { useAppSelector } from '../stores/useReduxTK';
import { RootState } from '../stores/store';
import { en, TKeys } from './en';
import { fr } from './fr';
import React, { useCallback } from 'react';

export enum AppLangs {
  fr = 'fr',
  en = 'en',
}

export type TTranslations = Record<AppLangs, string>;

export const getTranslation = (key: TKeys, lang: AppLangs) => {
  if (!key) return '';
  const fallback = en[key] || key;
  switch (lang) {
    case AppLangs.fr:
      return fr[key] || fallback;
    case AppLangs.en:
      return en[key] || fallback;
    default:
      console.warn(`Unknown language key ${lang}`);
      return fallback;
  }
};

/*
   'fr-FR':
   'fr-BE':
   'en-US':
   'en-UK':
 */

export const getAppLang = (sysLang: string = 'en') =>
  Object.values(AppLangs).find((ln) => sysLang.toLowerCase().startsWith(ln)) || AppLangs.en;

export const browserAppLang = getAppLang(navigator.language || navigator?.['userLanguage']);

// FIXME use dynamic ln picker
export const getDefaultTranslator = (key: TKeys) => getTranslation(key, browserAppLang);

export const useTranslation = () => {
  const language = useAppSelector((state: RootState) => state.app.currentAppLang);

  return useCallback(
    (key: TKeys) => {
      return getTranslation(key, language);
    },
    [language]
  );
};
