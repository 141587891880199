import React, { ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { H1 } from '@/components/H1';
import { H2 } from '../H2';
import { CircularProgress } from '@mui/material';

interface Props extends ComponentPropsWithRef<'div'> {
  direction?: 'row' | 'col';
  title?: string;
  loading?: boolean;
  subContainer?: boolean;
}

export const FlexContainer = (p: Props) => {
  const { className, title, loading, subContainer = false, direction = 'col', ...rest } = p;

  return (
    <div
      className={twMerge(
        `flex flex-1 h-full w-full flex-${direction} items-start justify-start p-2`,
        className
      )}
      {...rest}
    >
      {title && (
        <div className="flex flex-row items-center justify-center space-x-3 mb-3 p-1">
          {subContainer && title ? (<H2>{title}</H2>) : (<H1>{title}</H1>)}
          {loading && <CircularProgress size='2rem' />}
          {/* <span className={`${loading ? 'loading' : ''} loading-bars loading-md`} /> */}
        </div>
      )}
      {p.children}
    </div>
  );
};
